import React, {createContext, useContext, useEffect, useState} from 'react';
import {io} from 'socket.io-client';
import {UserContext} from "./UserContext";

const defaultSocketContext = {
    socket: null,
    loading: true,
};

export const SocketContext = createContext(defaultSocketContext);

const SocketProvider = ({children}) => {
    const {user} = useContext(UserContext);
    const [socketState, setSocketState] = useState(defaultSocketContext);

    const [errorCount, setErrorCount] = useState(0);

    useEffect(() => {
        if(!user) return;

        if(errorCount >= 10) return;

        const storedAuth = localStorage.getItem('authState');
        if(!storedAuth) return;

        const parsedAuth = JSON.parse(storedAuth);

        const socketConnection = io('https://czat.coffee:9898', {
            auth: {
                token: parsedAuth.token
            }
        });

        socketConnection.on('connect', () => {
            setSocketState({socket: socketConnection, loading: true});
        });

        socketConnection.on('error', (error) => {
            setSocketState({socket: null, loading: true});
        });

        socketConnection.on('connect_error', (error) => {
            setSocketState({socket: null, loading: true});

            setErrorCount(prev => prev + 1);
        });

        socketConnection.on('auth-error', () => {
            setSocketState({socket: null, loading: true});

            // retry connection
            const storedAuth = localStorage.getItem('authState');
            if(!storedAuth) return;

            const parsedAuth = JSON.parse(storedAuth);

            socketConnection.auth = { token: parsedAuth.token };
            socketConnection.connect();
        });

        socketConnection.on('disconnect', () => {
            setSocketState({socket: null, loading: true});
        });

        socketConnection.on('user.connected', () => {
            setSocketState({socket: socketConnection, loading: false});
        });

        setSocketState({socket: null, loading: true});

        return () => {
            socketConnection.disconnect();
        };

    }, [user]);

    return (
        <SocketContext.Provider value={socketState}>
            {children}
        </SocketContext.Provider>
    );
};

export default SocketProvider;
