import React, {createContext, useState, useEffect, useContext} from 'react';
import AxiosCreate from '../api/AxiosCreate';

const AuthContext = createContext();

const AuthProvider = ({children}) => {
    const [authState, setAuthState] = useState({
        token: null,
        refresh_token: null,
        isAuthenticated: false,
    });

    useEffect(() => {

        const storedAuth = localStorage.getItem('authState');
        if (storedAuth) {
            try {
                const parsedAuth = JSON.parse(storedAuth);
                setAuthState(parsedAuth);

                console.log("USER DATA")
            } catch (error) {
                console.error('Failed to parse authState from localStorage', error);
            }
        } else {
            if (window.location.pathname !== '/login') {
                logout();
               // console.error('No auth state in localStorage');
           //     window.location.href = "/login";
            }
        }

    }, []);

    const login = async (login, password) => {
        try {
            const response = await AxiosCreate.post(`/auth/login`, {login: login, password: password});
            const {token, refresh_token} = response.data;

            setAuthState({
                token,
                refresh_token,
                isAuthenticated: true,
            });

            localStorage.setItem('authState', JSON.stringify({
                token,
                refresh_token,
                isAuthenticated: true,
            }));

          //  console.log("LOGIN REFRESH TOKEN", refresh_token);

            return true;

        } catch (error) {
            return error;
        //    console.error('Login failed', error);
        }
    };

    const refreshToken = async () => {

        const storedAuth = localStorage.getItem('authState');
        if(!storedAuth) return;

        const parsedAuth = JSON.parse(storedAuth);

     //   console.log("REFRESH TOKEN", parsedAuth.refresh_token);

        try {
            const response = await AxiosCreate.post('/auth/refresh-token', {refresh_token: parsedAuth.refresh_token});
            const {token, refresh_token, status} = response.data;

       //    console.log("NEW REFRESH TOKEN", refresh_token)


            if(status === 'success') {
                setAuthState(prevState => ({
                    isAuthenticated: true,
                    refresh_token,
                    token,
                }));

                localStorage.setItem('authState', JSON.stringify({
                    isAuthenticated: true,
                    refresh_token,
                    token,
                }));

                return token;
            }
            else {
               logout();
              //  console.error('status', status);
            }
        } catch (error) {
           // console.error('Token refresh failed', error);
            logout();
        }
    };

    const logout = () => {
        setAuthState({
            token: null,
            refresh_token: null,
            isAuthenticated: false,
        });
        localStorage.removeItem('authState');

        if (window.location.pathname !== '/login') {
            window.location.href = '/login';
        }
    };

    const getAuthState = () => authState;

    return (
        <AuthContext.Provider value={{authState, login, refreshToken, logout, getAuthState}}>
            {children}
        </AuthContext.Provider>
    );
};

export {AuthContext, AuthProvider};