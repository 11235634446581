import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Collapse, Dropdown} from 'react-bootstrap';
import Icon from '@mdi/react';
import {
    mdiSpeedometer, mdiImageFilterFrames, mdiAccountMultiple, mdiApplicationSettings, mdiMenuDown, mdiFormatPaint, mdiFormatListBulleted
} from "@mdi/js";

function Sidebar({user}) {
    const [openMenu, setOpenMenu] = React.useState({
        projects: false, users: false, configure: false
    });
    const location = useLocation();

    const isPathActive = (path) => {
        return location.pathname.startsWith(path);
    };

    // const toggleMenuState = (name) => {
    //     setOpenMenu((prevState) => ({
    //         ...prevState, [name]: !prevState[name],
    //     }));
    // }

    const [menuState, setMenuState] = React.useState({});
    //   const location = useLocation();

    const toggleMenuState = (menu) => {
        setMenuState((prevState) => {
            const newState = Object.keys(prevState).length === 0 ? {[menu]: true} : Object.keys(prevState).reduce((acc, key) => ({...acc, [key]: false}), {});
            return {...newState, [menu]: !prevState[menu]};
        });
    };

    const onRouteChanged = () => {
        document.querySelector('#sidebar').classList.remove('active');
        setMenuState({});

        const dropdownPaths = [{path: '/projects', state: 'projects'}, {path: '/users', state: 'users'}, {path: '/configure', state: 'configure'}];

        dropdownPaths.forEach((obj) => {
            if (isPathActive(obj.path)) {
                setMenuState((prevState) => ({...prevState, [obj.state]: true}));
            }
        });
    };

    React.useEffect(() => {
        onRouteChanged();
    }, [location]);

    React.useEffect(() => {
        const body = document.querySelector('body');
        const navItems = document.querySelectorAll('.sidebar .nav-item');

        const handleMouseOver = (el) => {
            if (body.classList.contains('sidebar-icon-only')) {
                el.classList.add('hover-open');
            }
        };

        const handleMouseOut = (el) => {
            if (body.classList.contains('sidebar-icon-only')) {
                el.classList.remove('hover-open');
            }
        };

        navItems.forEach((el) => {
            el.addEventListener('mouseover', () => handleMouseOver(el));
            el.addEventListener('mouseout', () => handleMouseOut(el));
        });

        // Clean up event listeners on unmount
        return () => {
            navItems.forEach((el) => {
                el.removeEventListener('mouseover', () => handleMouseOver(el));
                el.removeEventListener('mouseout', () => handleMouseOut(el));
            });
        };
    }, []);


    return (<nav className="sidebar sidebar-offcanvas" id="sidebar">
            <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
                {/*<a className="sidebar-brand brand-logo" href="">*/}
                    <img src={require('../assets/images/zabi.png')} alt={'ZABI'} className={'logo-image brand-logo'}/>
                {/*</a>*/}
                <a className="sidebar-brand brand-logo-mini" href="" style={{color: '#0672b2'}}>Z</a>
            </div>
        <ul className="nav">
            <li className="nav-item profile">
                <div className="profile-desc">
                    <div className="profile-pic">
                        <div className="count-indicator">
                            <img className="img-xs rounded-circle"
                                 src={require('../assets/images/default-avatar.jpg')} alt="profile"/>
                            <span className="count bg-success"></span>
                        </div>
                        <div className="profile-name">
                            <h5 className="mb-0 font-weight-normal">{user.display_name}</h5>
                            <span>{user.role}</span>
                        </div>
                    </div>
                </div>
            </li>
            <li className="nav-item nav-category">
                <span className="nav-link">Menu</span>
            </li>
            <li className={isPathActive('/dashboard') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                <Link className="nav-link" to="/dashboard">
                        <span className="menu-icon">
                            <Icon path={mdiSpeedometer} size={0.7}/>
                        </span>
                    <span className="menu-title">Dashboard</span>
                </Link>
            </li>
            <li className={isPathActive('/projects') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                <div className={menuState.projects ? 'nav-link menu-expanded' : 'nav-link'}
                     onClick={() => toggleMenuState('projects')} data-toggle="collapse">
                        <span className="menu-icon">
                            <Icon path={mdiImageFilterFrames} size={0.7}/>
                        </span>
                    <span className="menu-title">Projekty</span>
                    <Icon path={mdiMenuDown} size={0.7} className={"menu-arrow"}/>
                </div>
                <Collapse in={menuState.projects}>
                    <div>
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item">
                                <Link
                                    className={isPathActive('/projects/part-orders') ? 'nav-link active' : 'nav-link'}
                                    to="/projects/part-orders">Zlecenia części</Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className={isPathActive('/projects/internal-orders') ? 'nav-link active' : 'nav-link'}
                                    to="/projects/internal-orders">Zlecenia wewnętrzne</Link>
                            </li>
                        </ul>
                    </div>
                </Collapse>
            </li>
            <li className={isPathActive('/cnc-machining') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                <Link className="nav-link" to="/cnc-machining">
                        <span className="menu-icon">
                            <Icon path={mdiFormatPaint} size={0.7}/>
                        </span>
                    <span className="menu-title">Obróbka CNC</span>
                </Link>
            </li>
            <li className={isPathActive('/users') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                <div className={menuState.users ? 'nav-link menu-expanded' : 'nav-link'}
                     onClick={() => toggleMenuState('users')} data-toggle="collapse">
                        <span className="menu-icon">
                            <Icon path={mdiAccountMultiple} size={0.7}/>
                        </span>
                    <span className="menu-title">Użytkownicy</span>
                    <Icon path={mdiMenuDown} size={0.7} className={"menu-arrow"}/>
                </div>
                <Collapse in={menuState.users}>
                    <div>
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item">
                                <Link className={isPathActive('/users') ? 'nav-link active' : 'nav-link'}
                                      to="/users">Lista użytkowników</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={isPathActive('/roles') ? 'nav-link active' : 'nav-link'}
                                      to="/roles">Role</Link>
                            </li>
                        </ul>
                    </div>
                </Collapse>
            </li>
            <li className={isPathActive('/configure') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                <div className={menuState.configure ? 'nav-link menu-expanded' : 'nav-link'}
                     onClick={() => toggleMenuState('configure')} data-toggle="collapse">
                        <span className="menu-icon">
                            <Icon path={mdiApplicationSettings} size={0.7}/>
                        </span>
                    <span className="menu-title">Konfigurator</span>
                    <Icon path={mdiMenuDown} size={0.7} className={"menu-arrow"}/>
                </div>
                <Collapse in={menuState.configure}>
                    <div>
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item">
                                <Link className={isPathActive('/configure-status') ? 'nav-link active' : 'nav-link'}
                                      to="/configure-status">Statusy</Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className={isPathActive('/configure-machine') ? 'nav-link active' : 'nav-link'}
                                    to="/configure-machine">Maszyny</Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className={isPathActive('/configure-operator') ? 'nav-link active' : 'nav-link'}
                                    to="/configure-operator">Operatorzy</Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className={isPathActive('/configure-programmer') ? 'nav-link active' : 'nav-link'}
                                    to="/configure-programmer">Programiści</Link>
                            </li>
                        </ul>
                    </div>
                </Collapse>
            </li>
            <li className={isPathActive('/todo') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                <Link className="nav-link" to="/todo">
                        <span className="menu-icon">
                            <Icon path={mdiFormatListBulleted} size={0.7}/>
                        </span>
                    <span className="menu-title">TODO List</span>
                </Link>
            </li>
        </ul>
    </nav>);

}

export default Sidebar;