import {Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {complicatedStatus, TYPE_STATUS_CNC, YesOrNo} from "../helpers/Status";
import {fetchProjectCNCPerMachineForNoStatus} from "../api/ApiProject";
import useAxios from "../api/useAxios";
import Attachment from "./Attachment";
import {mdiClose} from "@mdi/js";
import Icon from "@mdi/react";
import TextWithTooltip from "./TextWitchTooltip";
import CustomSelect from "./CustomSelect";

const DashboardModalTableProjectComplicated = ({ data, show, setShow, statuses }) => {
    const axiosInstance = useAxios();

    const [parts, setParts] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(show) {
            getDataForMachine();
        }

        async function getDataForMachine() {
            setLoading(false);
            const status_cnc = statuses.find(s => s.kind_status === 'status_end' && parseInt(s.type_status) === TYPE_STATUS_CNC);

            const response = await fetchProjectCNCPerMachineForNoStatus(axiosInstance, status_cnc.id, data.machine_id);

            if(response.status === 'success') {
                setParts(response.projects);
                setLoading(true);
            }
        }

    }, [show])

    const handleStatusCNC = (status_cnc) => {
        const statusesCNC = statuses.filter(s => parseInt(s.type_status) === TYPE_STATUS_CNC);
        const status = statusesCNC.find((s) => parseInt(s.id) === parseInt(status_cnc));

        if (!status) {
            return (
                <div
                    className={`badge badge-${statusesCNC[0].color}`}>
                    {statusesCNC[0].display_name}
                </div>
            )
        }

        return (
            <div
                className={`badge badge-${status.color}`}>
                {status.display_name}
            </div>
        )
    }

    const handlePriority = (priority_id) => {
        const priority = complicatedStatus.find(p => p.id === parseInt(priority_id));

        if (!priority) {
            return (
                <div
                    className={`badge badge-outline-${complicatedStatus[0].color}`}>
                    {complicatedStatus[0].display_name}
                </div>
            )
        }

        return (
            <div
                className={`badge badge-outline-${priority.color}`}>
                {priority.display_name}
            </div>
        )
    }

    const handleDefaultMaterialOrder = (id) => {
        const materialOrder = statuses.find(s => parseInt(s.id) === parseInt(id));

        if(!materialOrder) {
            return {
                color: 'light',
                display_name: 'Brak'
            }
        }


        return materialOrder;
    }

    const handleDefaultYesOrNo = (id) => {
        const yn = YesOrNo.find(o => o.id === parseInt(id));

        if (!yn) {
            return {
                color: YesOrNo[0].color,
                display_name: YesOrNo[0].display_name
            }
        }

        return yn;
    }

    if(!loading) {
        return '';
    }

    return (
        <Modal show={show} onHide={() => setShow(false)} className={"custom-modal-style"}>
            <Modal.Header>
                <Modal.Title>Informacje o projektach</Modal.Title>
                <Icon path={mdiClose}
                      size={1}
                      onClick={() => setShow(false)}
                      className={"cursor-pointer"}
                />
            </Modal.Header>
            <Modal.Body>
                <p>Stopnia skomplikowania: {complicatedStatus.find(c => c.id === data.complicated_id).display_name}</p>
                <p>Maszyna: {data.machine_name}</p>

                <div className={`table-responsive ${parts.length ? 'scroll-table' : ''}`}>
                    <table className="table">
                        <thead>
                        <tr>
                            <th style={{width: "5%"}}>Lp.</th>
                            <th style={{width: "10.5%"}}>Nazwa projektu</th>
                            <th style={{width: "10.5%"}}>Pozycja<br/>Numer | Nazwa</th>
                            <th style={{width: "10.5%"}}>Część<br/>Numer | Nazwa</th>
                            <th style={{width: "6.5%"}}>Ilość</th>
                            <th style={{width: "13.5%"}}>Status obróbki</th>
                            <th style={{width: "10.5%"}}>Materiał<br/>zamówiony</th>
                            <th style={{width: "10.5%"}}>Materiał</th>
                            <th style={{width: "10.5%"}}>Hartowanie<br/>Azotowanie</th>
                            <th style={{width: "10.5%"}}>Wymiary</th>
                            <th style={{width: "10.5%"}}>Priorytet</th>
                            <th style={{width: "10.5%"}}>Załącznik</th>
                        </tr>
                        </thead>
                        <tbody>
                        {parts
                            .filter(part => parseInt(part.position_parts.complicated === null ? 1 : part.position_parts.complicated) === parseInt(data.complicated_id))
                            .map((part, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td><TextWithTooltip text={part.project_name}/></td>
                                    <td>{part.position_number}<br/><TextWithTooltip text={part.position_name}/>
                                    </td>
                                    <td>
                                        {part.position_parts.display_number}<br/><TextWithTooltip text={part.position_parts.display_name}/>
                                    </td>
                                    <td>{part.position_parts.quantity}</td>
                                    <td>{handleStatusCNC(part.position_parts.status_cnc)}</td>
                                    <td><CustomSelect
                                        title={"Materiał zamówiony"}
                                        defaultValue={handleDefaultMaterialOrder(part.position_parts.material_order)}
                                        customClass={"badge-sm"}
                                        clickable={false}
                                        isOutline={true}
                                    /></td>
                                    <td>{part.position_parts.name_material}</td>
                                    <td>
                                        <div
                                            className={"row"}>
                                            <div
                                                className={"col-6"}>
                                                <CustomSelect
                                                    title={"Hartowanie"}
                                                    defaultValue={handleDefaultYesOrNo(part.position_parts.temper)}
                                                    customClass={"badge-sm"}
                                                    clickable={false}
                                                    isOutline={true}
                                                >
                                                </CustomSelect>
                                            </div>
                                            <div
                                                className={"col-6"}>
                                                <CustomSelect
                                                    title={"Azotowanie"}
                                                    defaultValue={handleDefaultYesOrNo(part.position_parts.azotize)}
                                                    customClass={"badge-sm"}
                                                    clickable={false}
                                                    isOutline={true}
                                                >
                                                </CustomSelect>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{part.position_parts.dimensions}</td>
                                    <td>{handlePriority(part.position_parts.priority)}</td>
                                    <td><Attachment hyperlink={part.position_parts.hyperlink} /></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={() => setShow(false)}>Zamknij</button>
            </Modal.Footer>
        </Modal>
)
}

export default DashboardModalTableProjectComplicated;