import moment from 'moment';

export const translateBigCalendar = {
    allDay: 'Cały dzień',
    previous: 'Poprzedni',
    next: 'Następny',
    today: 'Dziś',
    month: 'Miesiąc',
    week: 'Tydzień',
    day: 'Dzień',
    agenda: 'Agenda',
    date: 'Data',
    time: 'Czas',
    event: 'Wydarzenie',
    noEventsInRange: 'Brak wydarzeń w tym zakresie.',
    showMore: (total) => `+ Pokaż więcej (${total})`,
}

export const scrollTime = new Date();
scrollTime.setHours(scrollTime.getHours() - 1);

export const filterEvents = (events, isSortable = true) => {
    const currentWeekEvents = [];
    const currentMonthEvents = [];
    const otherEvents = [];
    const finishedEvents = [];

    const startOfWeek = moment().startOf('isoWeek');
    const endOfWeek = moment().endOf('isoWeek');
    const startOfMonth = moment().startOf('month');
    const endOfMonth = moment().endOf('month');

    if(isSortable === false) {
        const notFinishEvent = events.filter(event => !event.is_finish);

        return {
            notFinishEvent: notFinishEvent
        }
    }
    else {
        events.forEach(event => {
            const eventStart = moment(event.start);
            const eventEnd = moment(event.end);

            if (event.is_finish) {
                finishedEvents.push(event);
            } else if (eventStart.isBetween(startOfWeek, endOfWeek, null, '[]') ||
                (eventStart.isBefore(endOfWeek) && eventEnd.isAfter(startOfWeek))) {
                currentWeekEvents.push(event);
            } else if (eventStart.isBetween(startOfMonth, endOfMonth, null, '[]') ||
                (eventStart.isBefore(endOfMonth) && eventEnd.isAfter(startOfMonth))) {
                currentMonthEvents.push(event);
            } else {
                otherEvents.push(event);
            }
        });

        return {
            currentWeekEvents,
            currentMonthEvents,
            otherEvents,
            finishedEvents
        };
    }
};