import React, {useEffect, useState} from "react";

const SearchInput = ({data, setFilteredData, _type = 'finish', index = 0}) => {
    const [searchQuery, setSearchQuery] = useState('');

    const clear = () => {
     //   setSearchQuery("");
    }

    useEffect(() => {
        const searchTerm = searchQuery.toLowerCase();
        let filteredData = null;

        if(_type === 'finish') {
            filteredData = data.filter(item => {
                return (
                    item.project_name.toLowerCase()?.includes(searchTerm) ||
                    item.position_name.toLowerCase()?.includes(searchTerm) ||
                    item.position_number.toLowerCase()?.includes(searchTerm) ||
                    item.position_parts.display_name?.toLowerCase().includes(searchTerm) ||
                    item.position_parts.display_number?.toLowerCase().includes(searchTerm) ||
                    item.position_parts.name_material?.toLowerCase().includes(searchTerm) ||
                    item.position_parts.performed?.toLowerCase().includes(searchTerm)
                    // item.position_parts.dimensions?.toLowerCase().includes(searchTerm)
                );
            });

            setFilteredData(filteredData);
        }
        else if(_type === 'machine') {
            filteredData = data[index].filter(item => {
                return (
                    item.position_name.toLowerCase()?.includes(searchTerm) ||
                    item.position_number.toLowerCase()?.includes(searchTerm) ||
                    item.position_parts.display_name?.toLowerCase().includes(searchTerm) ||
                    item.position_parts.display_number?.toLowerCase().includes(searchTerm) ||
                    item.position_parts.name_material?.toLowerCase().includes(searchTerm) ||
                    item.position_parts.performed?.toLowerCase().includes(searchTerm)
                    // item.position_parts.dimensions?.toLowerCase().includes(searchTerm)
                );
            });

            const updatedData = data.map((item, i) => {
                if (i === index) {
                    return filteredData;
                }
                return item;
            });

            setFilteredData(updatedData);
        }
        else if(_type === 'tobedone') {
            const filteredData = data
                .map(item => {
                    const matchingParts = item.position_parts.filter(part =>
                        part.display_name?.toLowerCase().includes(searchTerm) ||
                        part.display_number?.toLowerCase().includes(searchTerm) ||
                        part.name_material?.toLowerCase().includes(searchTerm) ||
                        part.performed?.toLowerCase().includes(searchTerm)
                    );

                    if (matchingParts.length > 0) {
                        return {
                            ...item,
                            position_parts: matchingParts
                        };
                    }


                    return null;
                })
                .filter(item => item !== null);

            setFilteredData(filteredData);
        }

    }, [searchQuery, data, setFilteredData]);

    return (
        <input
            className="form-control"
            type="text"
            placeholder="Wyszukaj..."
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            onClick={clear}
        />
    );
}

export default SearchInput;