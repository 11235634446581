import React from 'react';
import Icon from '@mdi/react';
import { mdiChatProcessingOutline } from '@mdi/js';

const IconButtonChat = ({handleOpenUsersListChat}) => {
    return (
        <React.Fragment>
            <div className="chat-icon" onClick={handleOpenUsersListChat}>
                <Icon path={mdiChatProcessingOutline} size={1} className='icon' />
            </div>
        </React.Fragment>
    );
}

export default IconButtonChat;