import React, {useContext, useEffect, useState} from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'moment/locale/pl';
import {filterEvents, scrollTime, translateBigCalendar} from "../../helpers/calendarHelper";
import Icon from "@mdi/react";
import {mdiDotsHorizontal, mdiMinus, mdiPlus} from "@mdi/js";
import TodoModalForm from "../../components/todo/TodoModalForm";
import { CSSTransition } from 'react-transition-group';
import {UserContext} from "../../contexts/UserContext";
import {fetchEditTodo, fetchRemoveTodo, fetchTodos, fetchUpdateTodo} from "../../api/ApiTodo";
import useAxios from "../../api/useAxios";
import RBCTooltip from "../../components/RBCTooltip";
import { Tooltip } from 'react-tooltip';

moment.locale('pl');

const DragAndDropCalendar = withDragAndDrop(Calendar);
const localizer = momentLocalizer(moment);

const TodoList = () => {
    const axiosInstance = useAxios();
    const {user} = useContext(UserContext);

    const [modalData, setModalData] = useState({
        id: null,
        isEdit: false,
        data: null
    });

    const [events, setEvents] = useState([]);

    const moveEvent = ({ event, start, end }) => {
        const updatedEvents = events.map((existingEvent) =>
            existingEvent.id === event.id
                ? { ...existingEvent, start, end }
                : existingEvent
        );

        setEvents(updatedEvents);

        fetchUpdateTodo(axiosInstance, event.id, {
            start: start,
            end: end,
        });
    };
    const eventStyleGetter = (event) => {
        return {
            style: {
                backgroundColor: event.color || 'rgba(50, 115, 220, 0.8)',
                borderRadius: '5px',
                color: 'white',
                border: '0px',
                display: 'block',
            },
        };
    };

    const removeEvent = (id) => {
        setEvents(events.filter((e) => e.id !== id));
        fetchRemoveTodo(axiosInstance, id);
    }

    const [showModal, setShowModal] = useState(false);
    const [isSort, setIsSort] = useState(false);

    const [isOpenSlideBox, setIsOpenSlideBox] = useState([true, true, true, true, true]);
    const updateSlideBoxState = (index, newValue) => {
        setIsOpenSlideBox(prevState => {
            const newState = [...prevState];
            newState[index] = newValue;
            return newState;
        });
    };

    useEffect(() => {
        getTodos();

        async function getTodos() {
            const response = await fetchTodos(axiosInstance, user.id_user);

            if(response.status === 'success') {

                const arrayTodos = [];
                response.todos.forEach(value => {
                    arrayTodos.push({
                        id: value.id,
                        title: value.title,
                        description: value.description,
                        start: new Date(value.start),
                        end: new Date(value.end),
                        color: value.color,
                        is_finish: value.is_finish
                    });
                });

               setEvents(arrayTodos);
            }
        }
    }, []);

    const handleCreateEvent = () => {
        setModalData({
            isEdit: false,
            data: null
        });
        setShowModal(true);
    }

    const handleClickEvent = (event) => {
        setModalData({
            isEdit: true,
            data: event
        });
        setShowModal(true);
    }

    const eventBigCalendar = ({event}) => {
        return (
            <div style={{height: '100%'}} data-tooltip-content={event.description} data-tooltip-id={`tooltip-${event.id}`} onClick={() => handleClickEvent(event)}>
                <p className={"rbc-event-custom-title"}>{event.title}</p>
                <br/>
                <Tooltip id={`tooltip-${event.id}`}
                         place="top"
                         style={{maxWidth: '200px', whiteSpace: 'normal'}}
                />
            </div>
        )
    }

    const handleFinishedEvent = (id_event) => {
        const index = events.findIndex((event) => event.id === id_event);
        if(typeof events[index].is_finish === 'undefined' || events[index].is_finish === null) {
            events[index].is_finish = false;
        }
        events[index].is_finish = !events[index].is_finish;
        setEvents([...events]);

        fetchEditTodo(axiosInstance, id_event, {
            id_user: user.id_user,
            title: events[index].title,
            description: events[index].description,
            start: events[index].start,
            end: events[index].end,
            color: events[index].color,
            is_finish: events[index].is_finish
        });
    }

    const renderSortList = () => {
        const filteredEvents = filterEvents(events);

        return (
            <React.Fragment>
                <div className="todo-box">
                    <div className="todo-header">
                        <p>Ten tydzień</p>
                        <Icon path={!isOpenSlideBox[0] ? mdiPlus : mdiMinus} size={0.6}
                              className="icon"
                              onClick={() => updateSlideBoxState(0, !isOpenSlideBox[0])}
                        />
                    </div>
                    <CSSTransition
                        in={isOpenSlideBox[0]}
                        timeout={300}
                        classNames="slide"
                        unmountOnExit
                    >
                        <div className="todo-content">
                            <ul className="d-flex flex-column-reverse text-white todo-list todo-list-custom">
                                {filteredEvents.currentWeekEvents.map(cwe => (
                                    <li key={`cwe_${cwe.id}`}>
                                        <div className="form-check form-check-primary">
                                            <label className="form-check-label">
                                                <input className="checkbox"
                                                       type="checkbox"
                                                       {...(cwe.is_finish ? {checked: true} : {})}
                                                       onChange={() => handleFinishedEvent(cwe.id)}
                                                />
                                                {cwe.title}
                                                <i className="input-helper"></i>
                                            </label>
                                        </div>
                                        <i className="remove mdi mdi-close-box" onClick={() => removeEvent(cwe.id)}></i>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </CSSTransition>
                </div>
                <div className="todo-box">
                    <div className="todo-header">
                        <p>Ten miesiąc</p>
                        <Icon path={!isOpenSlideBox[1] ? mdiPlus : mdiMinus} size={0.6}
                              className="icon"
                              onClick={() => updateSlideBoxState(1, !isOpenSlideBox[1])}
                        />
                    </div>
                    <CSSTransition
                        in={isOpenSlideBox[1]}
                        timeout={300}
                        classNames="slide"
                        unmountOnExit
                    >
                        <div className="todo-content">
                            <ul className="d-flex flex-column-reverse text-white todo-list todo-list-custom">
                                {filteredEvents.currentMonthEvents.map(cme => (
                                    <li key={`cme_${cme.id}`}>
                                        <div className="form-check form-check-primary">
                                            <label className="form-check-label">
                                                <input className="checkbox"
                                                       type="checkbox"
                                                         {...(cme.is_finish ? {checked: true} : {})}
                                                       onChange={() => handleFinishedEvent(cme.id)}
                                                />
                                                {cme.title}
                                                <i className="input-helper"></i></label>
                                        </div>
                                        <i className="remove mdi mdi-close-box" onClick={() => removeEvent(cme.id)}></i>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </CSSTransition>
                </div>
                <div className="todo-box">
                    <div className="todo-header">
                        <p>Pozostałe</p>
                        <Icon path={!isOpenSlideBox[2] ? mdiPlus : mdiMinus} size={0.6}
                              className="icon"
                              onClick={() => updateSlideBoxState(2, !isOpenSlideBox[2])}
                        />
                    </div>
                    <CSSTransition
                        in={isOpenSlideBox[2]}
                        timeout={300}
                        classNames="slide"
                        unmountOnExit
                    >
                        <div className="todo-content">
                            <ul className="d-flex flex-column-reverse text-white todo-list todo-list-custom">
                                {filteredEvents.otherEvents.map(oe => (
                                    <li key={`oe_${oe.id}`}>
                                        <div className="form-check form-check-primary">
                                            <label className="form-check-label">
                                                <input className="checkbox"
                                                       type="checkbox"
                                                         {...(oe.is_finish ? {checked: true} : {})}
                                                       onChange={() => handleFinishedEvent(oe.id)}
                                                />
                                                {oe.title}
                                                <i className="input-helper"></i></label>
                                        </div>
                                        <i className="remove mdi mdi-close-box" onClick={() => removeEvent(oe.id)}></i>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </CSSTransition>
                </div>
            </React.Fragment>
        )
    }

    const renderTodoList = () => {
        return (
            <div className="todo-box">
                <div className="todo-header">
                    <p>Lista TODO</p>
                    <Icon
                        path={!isOpenSlideBox[3] ? mdiPlus : mdiMinus} size={0.6}
                        className="icon"
                        onClick={() => updateSlideBoxState(3, !isOpenSlideBox[3])}
                    />
                </div>
                <CSSTransition
                    in={isOpenSlideBox[3]}
                    timeout={300}
                    classNames="slide"
                    unmountOnExit
                >
                    <div className="todo-content">
                        <ul className="d-flex flex-column-reverse text-white todo-list todo-list-custom">
                            {filterEvents(events, false).notFinishEvent.map((e) => (
                                <li key={`e_${e.id}`}>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input className="checkbox"
                                                   type="checkbox"
                                                   {...(e.is_finish ? {checked: true} : {})}
                                                   onChange={() => handleFinishedEvent(e.id)}
                                            />
                                            {e.title}
                                            <i className="input-helper"></i>
                                        </label>
                                    </div>
                                    <i className="remove mdi mdi-close-box" onClick={() => removeEvent(e.id)}></i>
                                </li>
                            ))}
                        </ul>
                    </div>
                </CSSTransition>
            </div>
        )
    }

    return (
        <>
            <div className="page-header">
                <h3 className="page-title"> Lista rzeczy do zrobienia </h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">Lista rzeczy do zrobienia</li>
                    </ol>
                </nav>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <div className="todo-container">

                        <div className="buttons">
                            <button title="Dodaj nowe wydarzenie"
                                    onClick={() => handleCreateEvent()}
                            >
                                <Icon path={mdiPlus} size={0.6}
                                      className="icon"
                                />
                            </button>
                            <button title="Posegreguj" onClick={() => setIsSort(!isSort)}>
                                <Icon path={mdiDotsHorizontal} size={0.6} className="icon"/>
                            </button>
                        </div>

                        <div className="lists">
                            {isSort ? renderSortList() : renderTodoList()}

                            <div className="todo-box">
                                <div className="todo-header">
                                    <p>Zakończone</p>
                                    <Icon path={!isOpenSlideBox[4] ? mdiPlus : mdiMinus} size={0.6}
                                          className="icon"
                                          onClick={() => updateSlideBoxState(4, !isOpenSlideBox[4])}
                                    />
                                </div>
                                <CSSTransition
                                    in={isOpenSlideBox[4]}
                                    timeout={300}
                                    classNames="slide"
                                    unmountOnExit
                                >
                                    <div className="todo-content">
                                        <ul className="d-flex flex-column-reverse text-white todo-list todo-list-custom">
                                            {filterEvents(events).finishedEvents.map(oe => (
                                                <li key={`oe_${oe.id}`}>
                                                    <div className="form-check form-check-primary">
                                                        <label className="form-check-label">
                                                            <input className="checkbox"
                                                                   type="checkbox"
                                                                     {...(oe.is_finish ? {checked: true} : {})}
                                                                   onChange={() => handleFinishedEvent(oe.id)}
                                                            />
                                                            {oe.title} <i className="input-helper"></i>
                                                        </label>
                                                    </div>
                                                    <i className="remove mdi mdi-close-box" onClick={() => removeEvent(oe.id)}></i>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </CSSTransition>
                            </div>
                        </div>

                    </div>

                </div>
                <div className="col-md-1"></div>
                <div className="col-md-8">
                    <DndProvider backend={HTML5Backend}>
                        <div style={{height: 'calc(100vh - 300px)'}}>
                            <DragAndDropCalendar
                                defaultView={'week'}
                                localizer={localizer}
                                events={events}
                                startAccessor="start"
                                endAccessor="end"
                                onEventDrop={moveEvent}
                                onEventResize={moveEvent}
                                resizable
                                eventPropGetter={eventStyleGetter}
                                messages={translateBigCalendar}
                                scrollToTime={scrollTime}
                                style={{height: '100%'}}
                                components={{
                                    event: eventBigCalendar
                                }}
                            />
                        </div>
                    </DndProvider>
                </div>
            </div>

            <TodoModalForm show={showModal} setShow={setShowModal} events={events} setEvents={setEvents} setModalData={setModalData} modalData={modalData}/>
        </>
    )
}

export default TodoList;