import {Button, Form, Modal} from "react-bootstrap";
import Icon from "@mdi/react";
import {mdiClose} from "@mdi/js";
import React, {useContext, useEffect, useState} from "react";
import {hexToRgba, rgbaToHex} from "../../helpers/globalHelpers";
import moment from 'moment';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import useAxios from "../../api/useAxios";
import {fetchCreateTodo, fetchEditTodo} from "../../api/ApiTodo";
import {AuthContext} from "../../contexts/AuthContext";
import {UserContext} from "../../contexts/UserContext";

const defaultData = {
    title: '',
    description: '',
    color: '#1bcdde',
}

const defaultRangeDate = [
    moment().format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD'),
]


const defaultRangeTime = {
    startTime: moment().format('HH:mm'),
    endTime: moment().add(1, 'hour').format('HH:mm'),
}

const TodoModalForm = ({show, setShow, events, setEvents, setModalData, modalData}) => {
    const axiosInstance = useAxios();
    const {user} = useContext(UserContext);

    const [newEvent, setNewEvent] = useState(defaultData);

    const [dateRange, setDateRange] = useState(defaultRangeDate);
    const [timeRange, setTimeRange] = useState(defaultRangeTime);

    useEffect(() => {
        if(!show) return;

        if(modalData.isEdit) {
            const dateStart = moment(modalData.data.start).format('YYYY-MM-DD');
            const timeStart = moment(modalData.data.start).format('HH:mm');
            const dateEnd = moment(modalData.data.end).format('YYYY-MM-DD');
            const timeEnd = moment(modalData.data.end).format('HH:mm');

            setTimeRange({startTime: timeStart, endTime: timeEnd});
            setDateRange([dateStart, dateEnd]);

            setNewEvent({
                title: modalData.data.title,
                description: modalData.data.description,
                color: rgbaToHex(modalData.data.color),
            });
        }
    }, [show]);

    const handleSubmit = async () => {
        if (!newEvent.title) {
            return;
        }

        const startDateTime = new Date(
            `${moment(dateRange[0]).format('YYYY-MM-DD')}T${timeRange.startTime}`
        );
        const endDateTime = new Date(
            `${moment(dateRange[1]).format('YYYY-MM-DD')}T${timeRange.endTime}`
        );

        const response = await fetchCreateTodo(axiosInstance, {
            id_user: user.id_user,
            title: newEvent.title,
            description: newEvent.description,
            start: startDateTime,
            end: endDateTime,
            color: hexToRgba(newEvent.color, 0.8),
        });

        if (response.status === 'success') {
            const event = {
                id: response.id,
                title: newEvent.title,
                description: newEvent.description,
                start: startDateTime,
                end: endDateTime,
                color: hexToRgba(newEvent.color, 0.8),
            };

            setEvents([...events, event]);
            setNewEvent(defaultData);
            setDateRange(defaultRangeDate);
            setTimeRange(defaultRangeTime);
            setShow(false);
        }
    };

    const handleEdit = async () => {
        if (!newEvent.title) {
            return;
        }

        const startDateTime = new Date(
            `${moment(dateRange[0]).format('YYYY-MM-DD')}T${timeRange.startTime}`
        );
        const endDateTime = new Date(
            `${moment(dateRange[1]).format('YYYY-MM-DD')}T${timeRange.endTime}`
        );

        const response = await fetchEditTodo(axiosInstance, modalData.data.id, {
            id_user: user.id_user,
            title: newEvent.title,
            description: newEvent.description,
            start: startDateTime,
            end: endDateTime,
            color: hexToRgba(newEvent.color, 0.8),
        });

        if (response.status === 'success') {
            const event = {
                id: response.id,
                title: newEvent.title,
                description: newEvent.description,
                start: startDateTime,
                end: endDateTime,
                color: hexToRgba(newEvent.color, 0.8),
            };

            const index = events.findIndex((event) => event.id === modalData.data.id);
            events[index] = event;
            setEvents([...events]);

            setNewEvent(defaultData);
            setDateRange(defaultRangeDate);
            setTimeRange(defaultRangeTime);
            setShow(false);
            setModalData({isEdit: false, data: null});
        }
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header>
                <Modal.Title>{modalData.isEdit ? 'Edytowanie wydarzenia' : 'Dodawanie wydarzenia'}</Modal.Title>
                <Icon path={mdiClose}
                      size={1}
                      onClick={() => setShow(false)}
                      className={"cursor-pointer"}
                />
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formTitle">
                        <Form.Label>Tytuł wydarzenia</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Wprowadź tytuł"
                            value={newEvent.title}
                            onChange={(e) => setNewEvent({...newEvent, title: e.target.value})}
                        />
                    </Form.Group>

                    <br/>
                    <Form.Group controlId="formDesc">
                        <Form.Label>Opis wydarzenia</Form.Label>
                        <textarea className={"form-control"}
                                  value={newEvent.description}
                                  onChange={(e) =>
                                      setNewEvent({...newEvent, description: e.target.value})}
                        ></textarea>
                    </Form.Group>

                    <br/>
                    <Form.Group controlId="formDateRange">
                        <Form.Label>Zakres dat</Form.Label>
                        <DateRangePicker
                            className="form-control"
                            onChange={(range) => setDateRange(range)}
                            minimumDate={new Date()}
                            value={dateRange}
                            startDate={dateRange ? dateRange.startDate : null}
                            endDate={dateRange ? dateRange.endDate : null}
                            format={"dd-MM-y"}
                        />
                    </Form.Group>

                    <br/>
                    <Form.Group controlId="formTimeRange">
                        <Form.Label>Zakres godzin</Form.Label>
                        <div style={{display: 'flex', gap: '10px'}}>
                            <Form.Control
                                type="time"
                                value={timeRange.startTime}
                                onChange={(e) =>
                                    setTimeRange({...timeRange, startTime: e.target.value, endTime: moment(e.target.value, 'HH:mm').add(1, 'hour').format('HH:mm')})
                                }
                            />
                            <Form.Control
                                type="time"
                                value={timeRange.endTime}
                                onChange={(e) =>
                                    setTimeRange({...timeRange, endTime: e.target.value})
                                }
                            />
                        </div>
                    </Form.Group>
                    <br/>

                    <Form.Group controlId="formColor">
                        <Form.Label>Kolor wydarzenia</Form.Label>
                        <Form.Control
                            type="color"
                            value={newEvent.color}
                            onChange={(e) => setNewEvent({...newEvent, color: e.target.value})}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Anuluj
                </Button>
                <Button variant="primary" onClick={modalData.isEdit ? handleEdit : handleSubmit}>
                    {modalData.isEdit ? 'Edytuj wydarzenie' : 'Zapisz wydarzenie'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default TodoModalForm;