import React, {useEffect, useRef, useState} from 'react';
import Icon from '@mdi/react';

const IconWithTooltip = ({ title, content, pathIcon }) => {
    const [isVisible, setIsVisible] = useState(false);
    const tooltipRef = useRef(null);


    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };
    //
    // const handleClickOutside = (event) => {
    //     if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
    //         setIsVisible(false);
    //     }
    // };

    const handleMouseOver = () => {
        setIsVisible(true);
    };

    const handleMouseOut = () => {
        setIsVisible(false);
    };

    // useEffect(() => {
    //     if (isVisible) {
    //         document.addEventListener('mousedown', handleClickOutside);
    //         return () => {
    //             document.removeEventListener('mousedown', handleClickOutside);
    //         };
    //     }
    // }, [isVisible]);


    return (
        <div style={{ position: 'relative', display: 'inline-block', padding: '0.15rem' }}
             onMouseOver={handleMouseOver}
             onMouseOut={handleMouseOut}
        >
            <Icon
                path={pathIcon}
                size={1}
              //  onClick={toggleVisibility}
                style={{ cursor: 'pointer' }}
            />
            {isVisible && (
                <div
                    ref={tooltipRef}
                    className="iconTooltip"
                >
                    <h4 style={{ margin: '0 0 8px 0', fontSize: '14px' }}>{title}</h4>
                    <p style={{ margin: 0, fontSize: '12px' }}>{content}</p>
                </div>
            )}
        </div>
    );
};

export default IconWithTooltip;
