import React from 'react';
import ChatMessageOverlay from "./ChatMessageOverlay";

const ChatMessages = ({openedChatMessages, setOpenedChatMessages, messages, setMessages, messageOverlayRef}) => {

    return (
        <React.Fragment>
            <div className="messages-overlay-list">
                {openedChatMessages.map((ChatMessage, index) => (
                    <ChatMessageOverlay
                        index={index}
                        key={`ChatMessageOverlay_${index}`}
                        Context={ChatMessage}
                        openedChatMessages={openedChatMessages}
                        setOpenedChatMessages={setOpenedChatMessages}
                        messages={messages} setMessages={setMessages}
                        messageOverlayRef={messageOverlayRef}
                    />
                ))}
            </div>
        </React.Fragment>
    )
}

export default ChatMessages;