import React, { useState, useRef, useEffect } from 'react';

const TextWithTooltip = ({ text, textLength = 30 }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState('bottom');
    const tooltipRef = useRef(null);
    const textRef = useRef(null); // Referencja do elementu tekstowego

    const getShortText = (text) => {
        if (text.length > textLength) {
            return text.substring(0, textLength - 2) + '...';
        }
        return text;
    };

    useEffect(() => {
        if (isHovered && tooltipRef.current && textRef.current) {
            const tooltipRect = tooltipRef.current.getBoundingClientRect();
            const textRect = textRef.current.getBoundingClientRect();
            const tableRect = textRef.current.closest('table').getBoundingClientRect(); // Uzyskanie pozycji tabeli

            // Sprawdzamy, czy tooltip wychodzi poza granice tabeli
            const spaceAbove = textRect.top - tableRect.top; // Miejsce nad tekstem
            const spaceBelow = tableRect.bottom - textRect.bottom; // Miejsce pod tekstem

            if (spaceBelow < tooltipRect.height && spaceAbove > tooltipRect.height) {
                // Tooltip wychodzi poza dół, więc umieszczamy go na górze
                setTooltipPosition('top');
            } else {
                // Domyślnie umieszczamy na dole
                setTooltipPosition('bottom');
            }
        }
    }, [isHovered]);

    return (
        <div
            style={{ position: 'relative', display: 'inline-block' }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <span ref={textRef}>{getShortText(text)}</span> {/* Dodajemy ref do elementu tekstowego */}

            {isHovered && text.length > textLength && (
                <div
                    ref={tooltipRef}
                    style={{
                        position: 'absolute',
                        backgroundColor: '#333',
                        color: '#fff',
                        padding: '5px',
                        borderRadius: '5px',
                        top: tooltipPosition === 'bottom' ? '100%' : 'auto',
                        bottom: tooltipPosition === 'top' ? '100%' : 'auto',
                        left: '50%',
                        transform: tooltipPosition === 'bottom' ? 'translate(-50%, 0)' : 'translate(-50%, -30%)',
                        zIndex: 10,
                        width: '100%',
                        maxWidth: '200px',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    {text}
                </div>
            )}
        </div>
    );
};

export default TextWithTooltip;
