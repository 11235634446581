import React, {
    useEffect,
    useRef,
    useState
} from 'react';
import {
    Link,
    useLocation
} from "react-router-dom";
import {
    mdiEmailArrowRight,
    mdiPlus
} from "@mdi/js";
import Icon
    from "@mdi/react";
import useAxios
    from "../../api/useAxios";
import {
    fetchProjectDelete,
    fetchProjectDetailPositionPartUpdateStatus,
    fetchProjectPositionPartUpdate,
    fetchProjectsList
} from "../../api/ApiProject";
import ProjectDetail, {
    projectPosition
} from "../../components/projects/ProjectDetail";
import ProjectDetailModalAddPosition
    from "../../components/projects/ProjectDetailModalAddPosition";
import ProjectDetailModalAddPart
    from "../../components/projects/ProjectDetailModalAddPart";
import {
    fetchGetStatusForType
} from "../../api/ApiStatus";
import {
    TYPE_MATERIAL_ORDER_ID,
    TYPE_STATUS_CNC,
    TYPE_STATUS_PROJECT_PART_ID,
    YesOrNo
} from "../../helpers/Status";
import PartModalDetail
    from "../../components/PartModalDetail";
import CustomSelect
    from "../../components/CustomSelect";
import TextWithTooltip
    from "../../components/TextWitchTooltip";
import {sortProjectPositionParts} from "../../helpers/projectHelper";


function Projects({
                      name,
                      btn_name,
                      type_project = 0,
                      uri,
                      statuses,
                      setShowReminder,
                      setDataReminder
                  }) {
    const axiosInstance = useAxios();
    const location = useLocation();
    const messageLocation = location.state?.message;
    const statusLocation = location.state?.status;

    const [partModalDetailShow, setPartModalDetailShow] = useState(false);
    const [partId, setPartId] = useState(null);

    const [messages, setMessages] = useState({
        message: messageLocation ? messageLocation : '',
        status: statusLocation ? statusLocation : ''
    });

    const hasRunGetProject = useRef(false);
    const [refreshProjects, setRefreshProjects] = useState(false);
    const [projects, setProjects] = useState([]);

    const [projectsFinish, setProjectsFinish] = useState([]);
    const [currentProjectFinishPage, setCurrentProjectFinishPage] = useState(1);
    const [totalProjectFinishPage, setTotalProjectFinishPage] = useState(1);

    const [showProjectPosition, setShowProjectPosition] = useState([]);
    const [showProjectPositionPart, setShowProjectPositionPart] = useState([]);

    const [projectPartStatuses, setProjectPartStatuses] = useState([]);
    const [projectPartCNCStatuses, setProjectPartCNCStatuses] = useState([]);

    // MODAL
    const [showProjectDetailModalAddPosition, setShowProjectDetailModalAddPosition] = useState(false);
    const [dataProjectDetailModalPosition, setDataProjectDetailModalPosition] = useState({
        title: projectPosition[0].name,
        positionSelectId: projectPosition[0].id,
        projectId: 0
    });

    const [showProjectDetailModalAddPart, setShowProjectDetailModalAddPart] = useState(false);
    const [dataProjectDetailModalAddPart, setDataProjectDetailModalAddPart] = useState({
        title: '',
        positionId: null,
        isEdit: false,
        idPart: null
    });

    // END MODAL

    // Detail project
    const [idProject, setIdProject] = useState(null);
    const [isActive, setIsActive] = useState(false);

    const handleDetailProject = (idP) => {
        setIdProject(idP);
        setDataProjectDetailModalPosition((prevState) => ({
            ...prevState,
            projectId: idP,
            positionSelectId: projectPosition[0].id
        }));
        setIsActive(true);
    }
    // End detail project

    const handleChangeShowProjectPosition = (name, value) => {
        setShowProjectPosition((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }
    const handleChangeShowProjectPositionPart = (name, value) => {
        setShowProjectPositionPart((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleRemoveProject = async (idProject) => {
        const response = await fetchProjectDelete(axiosInstance, idProject);

        if (response.status === 'success') {
            setRefreshProjects(true);
            setMessages({
                message: response.message,
                status: 'success'
            });
        } else {
            setMessages({
                message: response.message,
                status: 'danger'
            });
        }
    }

    useEffect(() => {
        setProjects([]);
        setRefreshProjects(true);
    }, [type_project])

    useEffect(() => {
        const status = statuses.filter((s) => parseInt(s.type_status) === TYPE_STATUS_PROJECT_PART_ID);
        const status_cnc = statuses.filter((s) => parseInt(s.type_status) === TYPE_STATUS_CNC);

        setProjectPartStatuses(status);
        setProjectPartCNCStatuses(status_cnc);
    }, [axiosInstance, type_project])

    useEffect(() => {
        if (refreshProjects || (!projects.length && !hasRunGetProject.current)) {
            projectsList();
        }

        async function projectsList() {
            setRefreshProjects(true);
            const response = await fetchProjectsList(axiosInstance, type_project);

            let projectsFinish = [];
            let projectsNotFinish = [];

            const status_cnc = statuses.filter((s) => parseInt(s.type_status) === TYPE_STATUS_CNC);
            const status = status_cnc.find((s) => s.kind_status === 'status_end');

            if (response.status === 'success') {
                response.projects.forEach((project) => {
                    let isFinished = 0;
                    let isFinishedPosition = 0;

                    project.project_positions.forEach((position) => {
                        position.project_position_part.forEach((part) => {
                            if (parseInt(part.status_cnc) === parseInt(status.id)) {
                                isFinished++;
                            }
                        });

                        if (isFinished === position.project_position_part.length && position.project_position_part.length > 0) {
                            isFinishedPosition++;
                        }
                    });

                    if (isFinishedPosition === project.project_positions.length && project.project_positions.length > 0) {
                        if (!projectsFinish.some(p => p.id_project === project.id_project)) {
                            projectsFinish.push(project);
                        }
                    } else {
                        if (!projectsNotFinish.some(p => p.id_project === project.id_project)) {
                            projectsNotFinish.push(project);
                        }
                    }
                });

                setProjectsFinish(projectsFinish);
                setProjects(sortProjectPositionParts(projectsNotFinish));

                setTotalProjectFinishPage(Math.ceil(projectsFinish.length / 10));

                let projectShowObj = {};
                let projectPartShowObj = {};
                for (const index in response.projects) {
                    const projectId = response.projects[index].id_project;
                    projectShowObj[`id_projects_${projectId}`] = false;

                    for (let i = 0; i < response.projects[index].project_positions.length; i++) {
                        const projectPositions = response.projects[index].project_positions;
                        projectPartShowObj[`id_projects_position_${projectPositions[i].id_position}`] = false
                    }
                }

                setShowProjectPosition(projectShowObj);
                setShowProjectPositionPart(projectPartShowObj);
            }

            hasRunGetProject.current = true;
            setRefreshProjects(false);
        }

    }, [refreshProjects, axiosInstance, type_project, refreshProjects, hasRunGetProject]);


    const handleProgressBarPosition = (positions) => {
        let countStatusFinished = 0;
        let countParts = 0;

        const status = projectPartStatuses.find((s) => s.kind_status === 'status_end');
        const status_cnc = projectPartCNCStatuses.find((s) => s.kind_status === 'status_end');

        if (status_cnc) {
            positions.forEach((position) => {
                position.project_position_part.forEach((part) => {
                    if (parseInt(part.status_cnc) === parseInt(status_cnc.id) && parseInt(part.status) !== parseInt(status.id)) {
                        countStatusFinished++;
                    }
                    if (parseInt(part.status) !== parseInt(status.id)) {
                        countParts++;
                    }
                })
            })
        }

        if (countStatusFinished === 0) {
            return 0;
        }

        return Math.floor((countStatusFinished / countParts) * 100);
    }

    const GetStatusForPart = (IdStatus) => {
        const status = projectPartStatuses.find((s) => parseInt(s.id) === parseInt(IdStatus));

        if (!status) {
            return {
                color: 'dark',
                display_name: 'Brak statusu'
            }
        }

        return {
            color: `${status.color}`,
            display_name: status.display_name
        }
    }

    const GetStatusCNCForPart = (IdStatus) => {
        const status = projectPartCNCStatuses.find((s) => parseInt(s.id) === parseInt(IdStatus));

        if (!status) {
            return {
                color: 'dark',
                display_name: 'Nie przypisano'
            }
        }

        return {
            color: `${status.color}`,
            display_name: status.display_name
        }
    }

    const GetMaterialOrderForPart = (idOrder) => {
        const materialOrder = statuses.find((mo) => parseInt(mo.id) === parseInt(idOrder));

        if (!materialOrder) {
            return {
                color: 'light',
                display_name: 'Brak'
            }
        }

        return {
            color: `${materialOrder.color}`,
            display_name: materialOrder.display_name
        }
    }

    const handleChangeCustomSelectPart = async (id_position_part, name, id) => {
        const status_cnc = projectPartCNCStatuses.find((s) => s.kind_status === 'status_end');
        const status = projectPartStatuses.find((s) => parseInt(s.id) === parseInt(id));

        const updatedProject = projects.map((project) => ({
            ...project,
            project_positions: project.project_positions.map((position) => ({
                ...position,
                project_position_part: position.project_position_part.map((part) => {
                    if (parseInt(part.id_position_part) === parseInt(id_position_part)) {
                        if (name === 'status') {
                            return {
                                ...part,
                                [name]: id,
                                status_cnc: status.kind_status !== 'status_end' ? 0 : status_cnc.id
                            };
                        } else {
                            return {
                                ...part,
                                [name]: id
                            };
                        }
                    }
                    return part;
                }),
            })),
        }));

        const allPartsCount = updatedProject.map((project) => {
            const allPartsCount = project.project_positions.reduce((total, position) => {
                return total + position.project_position_part.length;
            }, 0);

            const statusCNCEndCount = project.project_positions.reduce((count, position) => {
                return count + position.project_position_part.filter(part => part.status_cnc === status_cnc.id).length;
            }, 0);

            return {
                project,
                allPartsCount,
                statusCNCEndCount
            };
        });

        const newProjectsFinish = [];
        const newProjects = [];

        allPartsCount.forEach((p) => {
            if (p.allPartsCount === p.statusCNCEndCount) {
                newProjectsFinish.push(p.project);
            } else {
                newProjects.push(p.project);
            }
        });

        setProjectsFinish(prev => [...newProjectsFinish, ...prev]);
        setProjects(newProjects);


        if (name === 'status') {
            await fetchProjectPositionPartUpdate(axiosInstance, id_position_part, {
                [name]: id,
                status_cnc: status.kind_status !== 'status_end' ? 0 : status_cnc.id,
                id_machine: 0
            });
        } else {
            await fetchProjectPositionPartUpdate(axiosInstance, id_position_part, {[name]: id});
        }
    }

    const handleShowPartModalDetail = (part_id) => {
        setPartId(part_id);
        setPartModalDetailShow(true);
    }

    const handleDefaultYesOrNo = (id) => {
        const yn = YesOrNo.find(o => o.id === parseInt(id));

        if (!yn) {
            return {
                color: YesOrNo[0].color,
                display_name: YesOrNo[0].display_name
            }
        }

        return yn;
    }

    const handleSendReminder = (partId) => {
        setDataReminder(partId);
        setShowReminder(true);
    }

    return (<>
        <div
            className="page-header">
            <h3 className="page-title"> Lista
                projektów {name}</h3>
            <nav
                aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">Projekty</li>
                </ol>
            </nav>
        </div>

        <div
            className="row">
            <div
                className="col-lg-12 grid-margin stretch-card">
                <div
                    className="card">
                    <div
                        className="card-body">
                        {messages.message &&
                            <div
                                className={`alert alert-${messages.status} text-small`}>{messages.message}</div>}

                        <div
                            className="float-right">
                            <Link
                                to={`create`}
                                type="button"
                                className="btn btn-info btn-icon-text">
                                <Icon
                                    path={mdiPlus}
                                    size={0.7}/> {btn_name}
                            </Link>
                        </div>
                        <div
                            className="table-responsive">
                            <table
                                className="table">
                                <thead>
                                <tr>
                                    <th style={{width: "5%"}}>Lp.</th>
                                    <th style={{width: "19%"}}>Nazwa
                                        projektu
                                    </th>
                                    <th style={{width: "19%"}}>Klient</th>
                                    <th style={{width: "19%"}}>Progress</th>
                                    <th style={{width: "19%"}}>Data
                                        utworzenia
                                    </th>
                                    <th style={{width: "19%"}}>Akcje</th>
                                </tr>
                                </thead>
                                <tbody>
                                {refreshProjects && (
                                    <tr>
                                        <td colSpan="6"
                                            className="text-center">
                                            <div
                                                className="py-4">
                                                <div
                                                    className="spinner-border"
                                                    role="status">
                                                    <span
                                                        className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>)}
                                {projects.map((project, i) => (<>
                                    <tr key={`pro_${project.id_project}`}
                                        className={'hover'}>
                                        <td className={"table_clickable"}
                                            onClick={() =>
                                                handleChangeShowProjectPosition(`id_projects_${project.id_project}`,
                                                    !showProjectPosition[`id_projects_${project.id_project}`]
                                                )}>
                                            {i + 1}
                                        </td>
                                        <td className={"table_clickable"}
                                            onClick={() =>
                                                handleChangeShowProjectPosition(`id_projects_${project.id_project}`,
                                                    !showProjectPosition[`id_projects_${project.id_project}`]
                                                )}>
                                            {/*<strong>{project.project_name}</strong>*/}
                                            <TextWithTooltip
                                                text={project.project_name}/>
                                        </td>
                                        <td className={"table_clickable"}
                                            onClick={() =>
                                                handleChangeShowProjectPosition(`id_projects_${project.id_project}`,
                                                    !showProjectPosition[`id_projects_${project.id_project}`]
                                                )}>
                                            <TextWithTooltip
                                                text={project.project_client_name}/>
                                            {/*<strong>{project.project_client_name}</strong>*/}
                                        </td>
                                        <td className={"table_clickable"}
                                            onClick={() =>
                                                handleChangeShowProjectPosition(`id_projects_${project.id_project}`,
                                                    !showProjectPosition[`id_projects_${project.id_project}`]
                                                )}>
                                            <div
                                                className="progress">
                                                <div
                                                    className="progress-bar progress-bar-striped"
                                                    role="progressbar"
                                                    style={{width: `${handleProgressBarPosition(project.project_positions)}%`}}
                                                    aria-valuenow="10"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100">
                                                </div>
                                                <span
                                                    className={"progress-bar-span"}>{handleProgressBarPosition(project.project_positions)}%</span>
                                            </div>
                                        </td>
                                        <td className={"table_clickable"}
                                            onClick={() =>
                                                handleChangeShowProjectPosition(`id_projects_${project.id_project}`,
                                                    !showProjectPosition[`id_projects_${project.id_project}`]
                                                )}>
                                            {project.project_created_at}
                                        </td>
                                        <td>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    gap: '7px'
                                                }}>
                                                <button
                                                    type="button"
                                                    onClick={() => handleDetailProject(project.id_project)}
                                                    className="btn btn-warning btn-fw">Szczegóły
                                                </button>
                                                <Link
                                                    to={`/projects/${uri}/edit/${project.id_project}`}
                                                    type="button"
                                                    className="btn btn-primary btn-fw">Edytuj
                                                </Link>
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveProject(project.id_project)}
                                                    className="btn btn-danger btn-fw">Usuń
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    {project.project_positions.length > 0 &&
                                        <tr>
                                            <td colSpan="6"
                                                style={{padding: 0}}>
                                                <div
                                                    className={`collapsible-content ${showProjectPosition[`id_projects_${project.id_project}`] ? 'expanded' : ''}`}
                                                    style={{padding: '0 2rem'}}>
                                                    <table
                                                        className="table mb-0">
                                                        <thead>
                                                        <tr>
                                                            <th>Numer
                                                                pozycji
                                                            </th>
                                                            <th>Nazwa
                                                                pozycji
                                                            </th>
                                                            <th>Osoba
                                                                odpowiedzialna
                                                            </th>
                                                            <th>Progress</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {project.project_positions.map((position, j) => (<>
                                                            <tr key={`p_${position.id_position}_${j}_project_${i}`}
                                                                className={`${handleProgressBarPosition([position]) === 100 ? 'bg-green hover' : 'hover'}`}>
                                                                <td className={"table_clickable"}
                                                                    onClick={() =>
                                                                        handleChangeShowProjectPositionPart(`id_projects_position_${position.id_position}`,
                                                                            !showProjectPositionPart[`id_projects_position_${position.id_position}`]
                                                                        )}>
                                                                    {position.display_number ? position.display_number : '-'}
                                                                </td>
                                                                <td className={"table_clickable"}
                                                                    onClick={() =>
                                                                        handleChangeShowProjectPositionPart(`id_projects_position_${position.id_position}`,
                                                                            !showProjectPositionPart[`id_projects_position_${position.id_position}`]
                                                                        )}>
                                                                    {position.display_name}
                                                                </td>
                                                                <td className={"table_clickable"}
                                                                    onClick={() =>
                                                                        handleChangeShowProjectPositionPart(`id_projects_position_${position.id_position}`,
                                                                            !showProjectPositionPart[`id_projects_position_${position.id_position}`]
                                                                        )}>
                                                                    <div
                                                                        className={`badge badge-${position.user.color}`}>
                                                                        {position.user.display_name}
                                                                    </div>
                                                                </td>
                                                                <td className={"table_clickable"}
                                                                    onClick={() =>
                                                                        handleChangeShowProjectPositionPart(`id_projects_position_${position.id_position}`,
                                                                            !showProjectPositionPart[`id_projects_position_${position.id_position}`]
                                                                        )}>
                                                                    <div
                                                                        className="progress">
                                                                        <div
                                                                            className="progress-bar progress-bar-striped"
                                                                            role="progressbar"
                                                                            style={{width: `${handleProgressBarPosition([position])}%`}}
                                                                            aria-valuenow="10"
                                                                            aria-valuemin="0"
                                                                            aria-valuemax="100">
                                                                        </div>
                                                                        <span
                                                                            className={"progress-bar-span"}>{handleProgressBarPosition([position])}%</span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="4"
                                                                    style={{padding: 0}}>
                                                                    <div
                                                                        className={`collapsible-content ${showProjectPositionPart[`id_projects_position_${position.id_position}`] ? 'expanded' : ''}`}
                                                                        style={{padding: '0 2rem'}}>
                                                                        <table
                                                                            className="table mb-0">
                                                                            <thead>
                                                                            <tr>
                                                                                <th>Numer
                                                                                    częsci
                                                                                </th>
                                                                                <th>Nazwa
                                                                                    częsci
                                                                                </th>
                                                                                <th>Ilość</th>
                                                                                <th>Status</th>
                                                                                <th>Status
                                                                                    CNC
                                                                                </th>
                                                                                <th>Hartowanie
                                                                                    |
                                                                                    Azotowanie
                                                                                </th>
                                                                                <th>Materiał<br />zamówiony</th>
                                                                                <th>Materiał</th>
                                                                                <th>Opis</th>
                                                                                <th>Akcja</th>
                                                                            </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {position.project_position_part.map((part, i) => (
                                                                                <tr key={`pp_${part.id_position_part}`}
                                                                                    className={statuses.find(s => parseInt(s.id) === parseInt(part.status_cnc) && s.kind_status === 'status_end') ? 'bg-green hover' : 'hover'}>
                                                                                    <td onClick={() => handleShowPartModalDetail(part.id_position_part)}>{part.display_number}</td>
                                                                                    <td onClick={() => handleShowPartModalDetail(part.id_position_part)}><TextWithTooltip
                                                                                        text={part.display_name}/></td>
                                                                                    <td onClick={() => handleShowPartModalDetail(part.id_position_part)}>{part.quantity}</td>
                                                                                    <td>
                                                                                        {/*{GetStatusForPart(part.status)}*/}
                                                                                        <CustomSelect
                                                                                            defaultValue={GetStatusForPart(part.status)}
                                                                                            onSelect={(selected) => handleChangeCustomSelectPart(part.id_position_part, 'status', selected.id)}
                                                                                        >
                                                                                            {projectPartStatuses.map((option, i) => (
                                                                                                <div
                                                                                                    key={`my_select_o_${i}`}
                                                                                                    className={`cursor-pointer badge badge-${option.color}`}
                                                                                                    option={option}
                                                                                                >
                                                                                                    {option.display_name}
                                                                                                </div>
                                                                                            ))}
                                                                                        </CustomSelect>
                                                                                    </td>
                                                                                    <td>
                                                                                        <CustomSelect
                                                                                            defaultValue={GetStatusCNCForPart(part.status_cnc)}
                                                                                            clickable={false}
                                                                                        ></CustomSelect>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div
                                                                                            className={"row"}>
                                                                                            <div
                                                                                                className={"col-3"}>
                                                                                                <CustomSelect
                                                                                                    title={"Hartowanie"}
                                                                                                    defaultValue={handleDefaultYesOrNo(part.temper)}
                                                                                                    onSelect={(selected) => handleChangeCustomSelectPart(part.id_position_part, 'temper', selected.id)}
                                                                                                    customClass={"badge-sm"}
                                                                                                    isOutline={true}
                                                                                                >
                                                                                                    {YesOrNo.map((yn, index) => (
                                                                                                        <div
                                                                                                            key={`my_select_machine_${part.id_position_part}_c_h_${index}`}
                                                                                                            className={`cursor-pointer badge badge-${yn.color}`}
                                                                                                            option={yn}
                                                                                                        >
                                                                                                            {yn.display_name}
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </CustomSelect>
                                                                                            </div>
                                                                                            <div
                                                                                                className={"col-3"}>
                                                                                                <CustomSelect
                                                                                                    title={"Azotowanie"}
                                                                                                    defaultValue={handleDefaultYesOrNo(part.azotize)}
                                                                                                    onSelect={(selected) => handleChangeCustomSelectPart(part.id_position_part, 'azotize', selected.id)}
                                                                                                    customClass={"badge-sm"}
                                                                                                    isOutline={true}
                                                                                                >
                                                                                                    {YesOrNo.map((yn, index) => (
                                                                                                        <div
                                                                                                            key={`my_select_machine_${part.id_position_part}_a_${index}`}
                                                                                                            className={`cursor-pointer badge badge-${yn.color}`}
                                                                                                            option={yn}
                                                                                                        >
                                                                                                            {yn.display_name}
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </CustomSelect>
                                                                                            </div>
                                                                                        </div>

                                                                                    </td>
                                                                                    <td>
                                                                                        <CustomSelect
                                                                                            title={"Materiał zamówiony"}
                                                                                            defaultValue={GetMaterialOrderForPart(part.material_order)}
                                                                                            onSelect={(selected) => handleChangeCustomSelectPart(part.id_position_part, 'material_order', selected.id)}
                                                                                            customClass={"badge-sm"}
                                                                                            isOutline={true}
                                                                                        >
                                                                                            {statuses
                                                                                                .filter(mo => parseInt(mo.type_status) === TYPE_MATERIAL_ORDER_ID)
                                                                                                .map(mom => (
                                                                                                    <div
                                                                                                        className={`cursor-pointer badge badge-${mom.color}`}
                                                                                                        option={mom}
                                                                                                    >{mom.display_name}
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                        </CustomSelect>
                                                                                    </td>
                                                                                    <td onClick={() => handleShowPartModalDetail(part.id_position_part)}>{part.name_material}</td>
                                                                                    <td>
                                                                                        {part.description === "" ? "Brak" : <TextWithTooltip
                                                                                            text={part.description}/>}
                                                                                    </td>
                                                                                    <td>
                                                                                        <button title={"Wyślij przypomnienie"} className={'btn btn-primary'} onClick={() => handleSendReminder(part.id_position_part)}><Icon
                                                                                            path={mdiEmailArrowRight}
                                                                                            size={0.7}/></button>
                                                                                    </td>
                                                                                </tr>))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </>))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>}
                                </>))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="row mt-5">
            <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">Projekty zakończone</h4>
                        <div className={`table-responsive ${projectsFinish.length ? 'scroll-table' : ''}`}>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th style={{width: "5%"}}>Lp.</th>
                                    <th style={{width: "19%"}}>Nazwa projektu</th>
                                    <th style={{width: "19%"}}>Klient</th>
                                    <th style={{width: "19%"}}>Progress</th>
                                    <th style={{width: "19%"}}>Data utworzenia</th>
                                    <th style={{width: "19%"}}>Akcje</th>
                                </tr>
                                </thead>
                                <tbody>
                                {refreshProjects && (
                                    <tr>
                                        <td colSpan="6" className="text-center">
                                            <div className="py-4">
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>)}
                                {projectsFinish.map((project, i) => (<>
                                    <tr key={project.id_project}>
                                        <td className={"table_clickable"}
                                            onClick={() =>
                                                handleChangeShowProjectPosition(`id_projects_${project.id_project}`,
                                                    !showProjectPosition[`id_projects_${project.id_project}`]
                                                )}>
                                            {i + 1}
                                        </td>
                                        <td className={"table_clickable"}
                                            onClick={() =>
                                                handleChangeShowProjectPosition(`id_projects_${project.id_project}`,
                                                    !showProjectPosition[`id_projects_${project.id_project}`]
                                                )}>
                                            {/*<strong>{project.project_name}</strong>*/}
                                            <TextWithTooltip
                                                text={project.project_name}/>
                                        </td>
                                        <td className={"table_clickable"}
                                            onClick={() =>
                                                handleChangeShowProjectPosition(`id_projects_${project.id_project}`,
                                                    !showProjectPosition[`id_projects_${project.id_project}`]
                                                )}>
                                            <TextWithTooltip
                                                text={project.project_client_name}/>
                                            {/*<strong>{project.project_client_name}</strong>*/}
                                        </td>
                                        <td className={"table_clickable"}
                                            onClick={() =>
                                                handleChangeShowProjectPosition(`id_projects_${project.id_project}`,
                                                    !showProjectPosition[`id_projects_${project.id_project}`]
                                                )}>
                                            <div
                                                className="progress">
                                                <div
                                                    className="progress-bar progress-bar-striped"
                                                    role="progressbar"
                                                    style={{width: `100%`}}
                                                    aria-valuenow="10"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100">
                                                </div>
                                                <span
                                                    className={"progress-bar-span"}>100%</span>
                                            </div>
                                        </td>
                                        <td className={"table_clickable"}
                                            onClick={() =>
                                                handleChangeShowProjectPosition(`id_projects_${project.id_project}`,
                                                    !showProjectPosition[`id_projects_${project.id_project}`]
                                                )}>
                                            {project.project_created_at}
                                        </td>
                                        <td>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    gap: '7px'
                                                }}>
                                                <button
                                                    type="button"
                                                    onClick={() => handleDetailProject(project.id_project)}
                                                    className="btn btn-warning btn-fw">Szczegóły
                                                </button>
                                                <Link
                                                    to={`/projects/${uri}/edit/${project.id_project}`}
                                                    type="button"
                                                    className="btn btn-primary btn-fw">Edytuj
                                                </Link>
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveProject(project.id_project)}
                                                    className="btn btn-danger btn-fw">Usuń
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    {project.project_positions.length > 0 &&
                                        <tr>
                                            <td colSpan="6"
                                                style={{padding: 0}}>
                                                <div
                                                    className={`collapsible-content ${showProjectPosition[`id_projects_${project.id_project}`] ? 'expanded' : ''}`}
                                                    style={{padding: '0 2rem'}}>
                                                    <table
                                                        className="table mb-0">
                                                        <thead>
                                                        <tr>
                                                            <th>Numer pozycji</th>
                                                            <th>Nazwa pozycji</th>
                                                            <th>Osoba odpowiedzialna</th>
                                                            <th>Progress</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {project.project_positions.map((position, j) => (<>
                                                            <tr key={`p_${position.id_position}`}
                                                                className={`bg-green`}>
                                                                <td className={"table_clickable"}
                                                                    onClick={() =>
                                                                        handleChangeShowProjectPositionPart(`id_projects_position_${position.id_position}`,
                                                                            !showProjectPositionPart[`id_projects_position_${position.id_position}`]
                                                                        )}>
                                                                    {position.display_number ?
                                                                        <TextWithTooltip
                                                                            text={position.display_number}/> : '-'}
                                                                </td>
                                                                <td className={"table_clickable"}
                                                                    onClick={() =>
                                                                        handleChangeShowProjectPositionPart(`id_projects_position_${position.id_position}`,
                                                                            !showProjectPositionPart[`id_projects_position_${position.id_position}`]
                                                                        )}>
                                                                    <TextWithTooltip
                                                                        text={position.display_name}/>
                                                                </td>
                                                                <td className={"table_clickable"}
                                                                    onClick={() =>
                                                                        handleChangeShowProjectPositionPart(`id_projects_position_${position.id_position}`,
                                                                            !showProjectPositionPart[`id_projects_position_${position.id_position}`]
                                                                        )}>
                                                                    <div
                                                                        className={`badge badge-${position.user.color}`}>
                                                                        {position.user.display_name}
                                                                    </div>
                                                                </td>
                                                                <td className={"table_clickable"}
                                                                    onClick={() =>
                                                                        handleChangeShowProjectPositionPart(`id_projects_position_${position.id_position}`,
                                                                            !showProjectPositionPart[`id_projects_position_${position.id_position}`]
                                                                        )}>
                                                                    <div
                                                                        className="progress">
                                                                        <div
                                                                            className="progress-bar progress-bar-striped"
                                                                            role="progressbar"
                                                                            style={{width: `100%`}}
                                                                            aria-valuenow="10"
                                                                            aria-valuemin="0"
                                                                            aria-valuemax="100">
                                                                        </div>
                                                                        <span
                                                                            className={"progress-bar-span"}>100%</span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="4"
                                                                    style={{padding: 0}}>
                                                                    <div
                                                                        className={`collapsible-content ${showProjectPositionPart[`id_projects_position_${position.id_position}`] ? 'expanded' : ''}`}
                                                                        style={{padding: '0 2rem'}}>
                                                                        <table
                                                                            className="table mb-0">
                                                                            <thead>
                                                                            <tr>
                                                                                <th>Numer
                                                                                    częsci
                                                                                </th>
                                                                                <th>Nazwa
                                                                                    częsci
                                                                                </th>
                                                                                <th>Ilość</th>
                                                                                <th>Status</th>
                                                                                <th>Status
                                                                                    CNC
                                                                                </th>
                                                                                <th>Hartowanie
                                                                                    |
                                                                                    Azotowanie
                                                                                </th>
                                                                                <th>Materiał</th>
                                                                            </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {position.project_position_part.map((part, i) => (
                                                                                <tr key={`pp_${part.id_position_part}`}>
                                                                                    <td onClick={() => handleShowPartModalDetail(part.id_position_part)}>{part.display_number}</td>
                                                                                    <td onClick={() => handleShowPartModalDetail(part.id_position_part)}>
                                                                                        <TextWithTooltip
                                                                                            text={part.display_name}/>
                                                                                    </td>
                                                                                    <td onClick={() => handleShowPartModalDetail(part.id_position_part)}>{part.quantity}</td>
                                                                                    <td>
                                                                                        {/*{GetStatusForPart(part.status)}*/}
                                                                                        <CustomSelect
                                                                                            defaultValue={GetStatusForPart(part.status)}
                                                                                            clickable={false}
                                                                                            onSelect={(selected) => handleChangeCustomSelectPart(part.id_position_part, 'status', selected.id)}
                                                                                        >
                                                                                            {projectPartStatuses.map((option, i) => (
                                                                                                <div
                                                                                                    key={`my_select_o_${i}`}
                                                                                                    className={`cursor-pointer badge badge-${option.color}`}
                                                                                                    option={option}
                                                                                                >
                                                                                                    {option.display_name}
                                                                                                </div>
                                                                                            ))}
                                                                                        </CustomSelect>
                                                                                    </td>
                                                                                    <td>
                                                                                        <CustomSelect
                                                                                            defaultValue={GetStatusCNCForPart(part.status_cnc)}
                                                                                            clickable={false}
                                                                                        ></CustomSelect>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div
                                                                                            className={"row"}>
                                                                                            <div
                                                                                                className={"col-3"}>
                                                                                                <CustomSelect
                                                                                                    title={"Hartowanie"}
                                                                                                    defaultValue={handleDefaultYesOrNo(part.temper)}
                                                                                                    onSelect={(selected) => handleChangeCustomSelectPart(part.id_position_part, 'temper', selected.id)}
                                                                                                    customClass={"badge-sm"}
                                                                                                    clickable={false}
                                                                                                    isOutline={true}
                                                                                                >
                                                                                                    {YesOrNo.map((yn, index) => (
                                                                                                        <div
                                                                                                            key={`my_select_machine_${part.id_position_part}_c_h_${index}`}
                                                                                                            className={`cursor-pointer badge badge-${yn.color}`}
                                                                                                            option={yn}
                                                                                                        >
                                                                                                            {yn.display_name}
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </CustomSelect>
                                                                                            </div>
                                                                                            <div
                                                                                                className={"col-3"}>
                                                                                                <CustomSelect
                                                                                                    title={"Azotowanie"}
                                                                                                    defaultValue={handleDefaultYesOrNo(part.azotize)}
                                                                                                    onSelect={(selected) => handleChangeCustomSelectPart(part.id_position_part, 'azotize', selected.id)}
                                                                                                    customClass={"badge-sm"}
                                                                                                    clickable={false}
                                                                                                    isOutline={true}
                                                                                                >
                                                                                                    {YesOrNo.map((yn, index) => (
                                                                                                        <div
                                                                                                            key={`my_select_machine_${part.id_position_part}_a_${index}`}
                                                                                                            className={`cursor-pointer badge badge-${yn.color}`}
                                                                                                            option={yn}
                                                                                                        >
                                                                                                            {yn.display_name}
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </CustomSelect>
                                                                                            </div>
                                                                                        </div>

                                                                                    </td>
                                                                                    <td onClick={() => handleShowPartModalDetail(part.id_position_part)}>{part.name_material}</td>
                                                                                </tr>))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </>))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>}
                                </>))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ProjectDetail
            isActive={isActive}
            setIsActive={setIsActive}
            idProject={idProject}
            setIdProject={setIdProject}
            statuses={statuses}

            setRefreshProject={setRefreshProjects}

            setShowModalPositionAdd={setShowProjectDetailModalAddPosition}
            showProjectDetailModalAddPosition={showProjectDetailModalAddPosition}
            setDataProjectDetailModalPosition={setDataProjectDetailModalPosition}

            showProjectDetailModalAddPart={showProjectDetailModalAddPart}
            setDataProjectDetailModalAddPart={setDataProjectDetailModalAddPart}
            setShowProjectDetailModalAddPart={setShowProjectDetailModalAddPart}

            projectPartStatuses={projectPartStatuses}
            projectPartCNCStatuses={projectPartCNCStatuses}
            GetStatusCNCForPart={GetStatusCNCForPart}
            GetMaterialOrderForPart={GetMaterialOrderForPart}
            handleDefaultYesOrNo={handleDefaultYesOrNo}

            setShowReminder={setShowReminder}
            setDataReminder={setDataReminder}
        />

        <ProjectDetailModalAddPosition
            show={showProjectDetailModalAddPosition}
            setShow={setShowProjectDetailModalAddPosition}
            setDataProjectDetailModalPosition={setDataProjectDetailModalPosition}
            dataModal={dataProjectDetailModalPosition}
        />
        <ProjectDetailModalAddPart
            show={showProjectDetailModalAddPart}
            setShow={setShowProjectDetailModalAddPart}
            dataModal={dataProjectDetailModalAddPart}
            statuses={statuses}
        />
        <PartModalDetail
            part_id={partId}
            setShow={setPartModalDetailShow}
            show={partModalDetailShow}
            statuses={statuses}/>
    </>)
}

export default Projects;