import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../contexts/AuthContext";
import {useNavigate} from "react-router-dom";

function Login() {
    const navigate = useNavigate();

    const {login, authState} = useContext(AuthContext);
    const [inputLogin,setInputLogin] = useState('');
    const [inputPassword,setInputPassword] = useState('');

    const [messages, setMessages] = useState({message: '', status: ''});

    const handleSubmit = async (e) => {
        e.preventDefault();
        const result = await login(inputLogin, inputPassword);

        if(result === true) {
            navigate('/dashboard');
        }
        else {
            setMessages({
                message: "Nieprawidłowy login lub hasło",
                status: 'danger'
            });
        }
    }

    useEffect(() => {
        if(authState.isAuthenticated) {
            navigate('/dashboard');
        }
    }, [authState, navigate]);

    return (
        <div>
            {!authState.isAuthenticated && <div className="d-flex align-items-center auth px-0">
                <div className="row w-100 m-0">
                    <div className="content-wrapper full-page-wrapper d-flex align-items-center auth login-bg">
                        <div className="card col-lg-4 mx-auto">
                            <div className="card-body px-5 py-5">
                                {messages.message && <div className={`alert alert-${messages.status} text-small`}>{messages.message}</div>}
                                <h3 className="card-title text-left mb-3">Logowanie</h3>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label>Login lub Email *</label>
                                        <input name="login" type="text"
                                               className="form-control p_input"
                                               value={inputLogin}
                                               onChange={(e) => setInputLogin(e.target.value)}
                                               required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Hasło *</label>
                                        <input name="password" type="password"
                                               className="form-control p_input"
                                               value={inputPassword}
                                               onChange={(e) => setInputPassword(e.target.value)}
                                               required
                                        />
                                    </div>
                                    <div className="form-group d-flex align-items-center justify-content-between">
                                        <a href="#" className="forgot-pass">Zapomniałem hasła</a>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-primary btn-block enter-btn">Zaloguj
                                            się
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default Login;