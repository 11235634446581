import React, { useState } from 'react';

const RBCTooltip = ({ text, color }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div
            style={{ position: 'relative', display: 'inline-block' }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {isHovered && (
                <div
                    style={{
                        position: 'absolute',
                        backgroundColor: `${color || '#333'}`,
                        color: '#fff',
                        padding: '5px',
                        borderRadius: '5px',
                        whiteSpace: 'nowrap',
                        top: '100%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 99999999999999999999,
                    }}
                >
                    {text}
                </div>
            )}
        </div>
    );
};

export default RBCTooltip;