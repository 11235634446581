export const fetchTodos = async (axiosInstance, userId) => {
    try {
        const response = await axiosInstance.get(`/todos/${userId}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const fetchCreateTodo = async (axiosInstance, todo) => {
    try {
        const response = await axiosInstance.post('/todo/create', todo);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const fetchEditTodo = async (axiosInstance, todoId, data) => {
    try {
        const response = await axiosInstance.put(`/todo/edit/${todoId}`, data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const fetchUpdateTodo = async (axiosInstance, todoId, todo) => {
    try {
        const response = await axiosInstance.put(`/todo/update/${todoId}`, todo);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const fetchRemoveTodo = async (axiosInstance, todoId) => {
    try {
        const response = await axiosInstance.delete(`/todo/delete/${todoId}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}