import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from "./contexts/AuthContext";
import UserProvider from "./contexts/UserContext";
import SocketProvider from "./contexts/SocketContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   // <React.StrictMode>
      <AuthProvider>
          <UserProvider>
              <SocketProvider>
                  <App />
              </SocketProvider>
          </UserProvider>
      </AuthProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
