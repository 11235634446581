export const handleIsValid = (value) => {
    return value === '' ? 'is-invalid' : '';
}

export const hexToRgba = (hex, alpha = 1) => {
    hex = hex.replace('#', '');

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const rgbaToHex = (rgba) => {
    const parts = rgba.match(/rgba?\((\d+), (\d+), (\d+)(?:, ([\d.]+))?\)/);
    const r = parseInt(parts[1]);
    const g = parseInt(parts[2]);
    const b = parseInt(parts[3]);
  //  const a = parts[4] ? parseFloat(parts[4]) : 1; // Alpha, domyślnie 1 (100%)


    const toHex = (num) => num.toString(16).padStart(2, '0');
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export function generateRoomId(userId1, userId2) {
    const sortedIds = [userId1, userId2].sort((a, b) => a - b);
    return sortedIds.join('_');
}