import React, {useContext, useEffect} from 'react';
import '../../assets/css/chat.scss';
import IconButtonChat from "./IconButtonChat";
import ChatUserList from "./ChatUserList";
import ChatMessages from "./ChatMessages";
import {SocketContext} from "../../contexts/SocketContext";
import {UserContext} from "../../contexts/UserContext";

const Chat = () => {
    const {socket} = useContext(SocketContext);
    const {user} = useContext(UserContext);

    const [isActiveUsersList, setIsActiveUserList] = React.useState(false);
    const [users, setUsers] = React.useState([]);

    const [openedChatMessages, setOpenedChatMessages] = React.useState([]);
    const [messages, setMessages] = React.useState([]);

    const messageOverlayRef = React.useRef([]);

    const handleOpenUsersListChat = () => {
        setIsActiveUserList(!isActiveUsersList);
    }

    useEffect(() => {
        if(!socket) return;

        socket.emit('user.list');

        socket.on('response.user.list', (usersList) => {
            setUsers(usersList);
            usersList.forEach(user => {
                // const index = parseInt(user.id) === -1 ? 0 : parseInt(user.id);
                messageOverlayRef.current[user.id] = null;

            });
        });

        socket.on('message.chat.read.receive', () => {
            socket.emit('user.list');
        })

        socket.on('message.chat.receive', function (data) {
            setMessages(prev => [...prev, data]);

            if(users.length) {
                if(data.sendTo === -1) {
                    const isOpenChat = openedChatMessages.find(message => Number(message.id) === -1);

                    if (!isOpenChat) {
                        const user = users.find(user => Number(user.id) === -1);
                        user.isClick = false;
                        setOpenedChatMessages([...openedChatMessages, user]);
                    }
                }
                else if (user.id_user !== data.senderId) {
                    const isOpenChat = openedChatMessages.find(message => Number(message.id) === Number(data.senderId));

                    if (!isOpenChat) {
                        const user = users.find(user => Number(user.id) === Number(data.senderId));
                        user.isClick = false;
                        setOpenedChatMessages([...openedChatMessages, user]);
                    }
                }


                if(user.id_user !== data.senderId) {
                    const audio = new Audio(require('../../assets/audio/message.mp3'));
                    audio.volume = 0.2;
                    audio.play().catch((error) => {
                        console.log(error);
                    });
                }
            }

            if(parseInt(data.sendTo) === parseInt(user.id_user)) {
                const index = data.senderId; // === -1 ? 0 : data.senderId;

                requestAnimationFrame(() => {
                    if(messageOverlayRef.current[index] !== null) {
                        messageOverlayRef.current[index].scrollTop = messageOverlayRef.current[index].scrollHeight;
                    }
                });
            }
            else {
                console.log(messageOverlayRef.current)
                const index = data.sendTo;// === -1 ? 0 : data.sendTo;
                requestAnimationFrame(() => {
                    if(messageOverlayRef.current[index] !== null) {
                        messageOverlayRef.current[index].scrollTop = messageOverlayRef.current[index].scrollHeight;
                    }
                });
            }
        });

        return () => {
            socket.off('message.chat.receive');
            socket.off('response.user.list');
            socket.off('message.chat.read.receive');
        }
    }, [socket]);

    return (
        <React.Fragment>
            {socket && <IconButtonChat handleOpenUsersListChat={handleOpenUsersListChat}/>}

            <ChatUserList
                isActive={isActiveUsersList} setIsActive={setIsActiveUserList}
                openedChatMessages={openedChatMessages} setOpenedChatMessages={setOpenedChatMessages}
                users={users}
            />

            <ChatMessages openedChatMessages={openedChatMessages} setOpenedChatMessages={setOpenedChatMessages}
                          messages={messages} setMessages={setMessages}
                          messageOverlayRef={messageOverlayRef} />

        </React.Fragment>
    );
}

export default Chat;