import {Button, Modal} from "react-bootstrap";
import Icon from "@mdi/react";
import {mdiClose} from "@mdi/js";
import React, {useEffect, useState} from "react";
import Select from 'react-select';
import useAxios from "../../api/useAxios";
import {fetchUserList} from "../../api/ApiUser";
import {fetchSendEmailReinder} from "../../api/ApiProject";

const ReminderModal = ({show, setShow, idPart}) => {
    const axiosInstance = useAxios();

    const [messages, setMessages] = useState({
        message: '', status: ''
    });

    const [selectedOption, setSelectedOption] = useState(null);
    const [optionsUsers, setOptionsUsers] = useState([]);
    const [statusOption, setStatusOption] = useState(0);

    useEffect(() => {
        async function getUsers() {
            const response = await fetchUserList(axiosInstance);

            if(response.status === 'success') {
                let users = [];
                response.users.forEach((u) => {
                    users.push({value: u.id, label: u.display_name});
                });

                setOptionsUsers(users);
            }
        }

        if(show) {
            getUsers();

            setMessages({
                message: "", status: ''
            });
            setSelectedOption(null);
            setStatusOption(0);
        }
    }, [show]);

    const handleSend = async () => {
        let ids_user = [];
        if(selectedOption !== null) {
            ids_user = selectedOption.map(item => parseInt(item.value));
        }

        const response = await fetchSendEmailReinder(axiosInstance, {
            ids_user: JSON.stringify(ids_user),
            status: statusOption,
            id_part: idPart
        });

        if(response.status !== 'success') {
            setMessages({
                message: response.message, status: 'danger'
            });
        }
        else {
            setMessages({
                message: response.message, status: 'success'
            });

            setSelectedOption(null);
            setStatusOption(0);
        }

        setSelectedOption(null);
        setStatusOption(0);
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header>
                <Modal.Title>Wyślij przypomnienie</Modal.Title>
                <Icon path={mdiClose}
                      size={1}
                      onClick={() => setShow(false)}
                      className={"cursor-pointer"}
                />
            </Modal.Header>
            <Modal.Body>
                {messages.message && <div className={`alert alert-${messages.status} text-small`}>{messages.message}</div>}
                <form className="forms-sample">
                    <div className="form-group">
                        <label htmlFor="example">Wyślij do</label>
                        <Select
                            value={selectedOption}
                            onChange={setSelectedOption}
                            options={optionsUsers}
                            isMulti={true}
                            isSearchable={true}
                            classNamePrefix={'select_new'}
                            placeholder={"Wybierz osoby"}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="example">Status</label>
                        <select className="form-control"
                                onChange={(e) => setStatusOption(parseInt(e.target.value))}
                                value={statusOption}
                        >
                            <option value={0}>Normalny</option>
                            <option value={1}>Pilny</option>
                        </select>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Zamknij
                </Button>
                <Button variant="primary" onClick={handleSend}>
                    Wyślij
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ReminderModal;