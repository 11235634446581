import React, {useEffect} from 'react';
import Icon from "@mdi/react";
import {mdiClose, mdiSend, mdiWindowMinimize} from "@mdi/js";
import {SocketContext} from "../../contexts/SocketContext";
import {UserContext} from "../../contexts/UserContext";
import {generateRoomId} from "../../helpers/globalHelpers";

const ChatMessageOverlay = ({index, Context, openedChatMessages, setOpenedChatMessages, messages, setMessages, messageOverlayRef}) => {
    const {socket} = React.useContext(SocketContext);
    const {user} = React.useContext(UserContext);

    const [isMinimalized, setIsMinimalized] = React.useState(false);
    const [isFocus, setIsFocus] = React.useState(Context.isClick);
    const [isLoading, setIsLoading] = React.useState(true);

    const TextAreaRef = React.useRef(null);

    const [message, setMessage] = React.useState('');

    const handleIsMaximized = () => {
        if(isMinimalized) {
            setIsMinimalized(false);
        }
    }

    const handleClear = () => {
        setMessage('');
        TextAreaRef.current.focus();
    }

    const handleOnChange = (e) => {
        setMessage(e.target.value);
    }

    const handleKeyDown = (e) => {
        if(e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    }

    const handleSendMessage = () => {
        if(message.trim() === '') return;

        socket.emit('message.chat.send', {message: message, sendTo: Context.id});

        handleClear();
    }

    const handleFocus = () => {
        setIsFocus(true);
    }

    useEffect(() => {
        if(!socket) return;

        socket.emit('message.chat.show', {roomId: Context.id === -1 ? 'room_group' : `room_${generateRoomId(user.id_user, Context.id)}`});

        socket.on('message.chat.show.receive', function (data) {
            const m = [...data.messages].reverse();

            console.log(m);

            m.forEach(message => {
                console.log("FIND: ", messages.find(msg => msg.id === message.id));
                if(!messages.find(msg => msg.id === message.id)) {
                    setMessages(prev => [...prev, message]);
                }
            })

            socket.emit('message.chat.read', {roomId: data.roomId});

            setTimeout(() => {
                setIsLoading(false);
            }, 200);
        });

        return () => {
            socket.off('message.chat.show.receive');
        }
    }, []);

    useEffect(() => {
        if(!socket) return;

        if(isFocus) {
            socket.emit('message.chat.read', {roomId: Context.id === -1 ? 'room_group' : `room_${generateRoomId(user.id_user, Context.id)}`});
            setIsFocus(false);
        }
    }, [isFocus]);

    useEffect(() => {
        if(!isLoading) {
            const index = Context.id;// === -1 ? 0 : Context.id;
            if(messageOverlayRef.current[index] !== null) {
                messageOverlayRef.current[index].scrollTop = messageOverlayRef.current[index].scrollHeight;
            }
        }
    }, [isLoading]);

    return (
        <React.Fragment>
            <div className={`chat-message-overlay ${isMinimalized ? 'minimalize' : ''}`}
                 onClick={handleFocus}
            >
                <div className="chat-message-overlay__header">
                    <div className="container"
                         onClick={handleIsMaximized}
                    >
                        <div className="avatar">
                            <img src={require('../../assets/images/default-avatar.jpg')} alt="profile"/>
                        </div>
                        <div className="info">
                            <span className="name">{Context.display_name}</span>
                            <span className={`status ${Context.isOnline ? 'online' : 'offline'}`}>{Context.isOnline ? 'Dostępny' : 'Niedostępny'}</span>
                        </div>
                    </div>
                    <div className="toolbox">
                        <Icon path={mdiWindowMinimize}
                              size={1} className='icon'
                              onClick={() => setIsMinimalized(!isMinimalized)}
                        />
                        <Icon path={mdiClose}
                              size={1} className='icon'
                                onClick={() => setOpenedChatMessages(prev => prev.filter(message => message.id !== Context.id))}
                        />
                    </div>
                </div>
                <div className="chat-message-overlay__body">
                    {isLoading && (
                        <div className="text-center">
                            <div className="loading">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    )}
                    {!isLoading && <React.Fragment>
                        <div className="messages" ref={(el) => (messageOverlayRef.current[Context.id] = el)}>
                            {messages
                                .filter(message => message.roomId === `room_${generateRoomId(user.id_user, Context.id)}` || (Context.id === -1 && message.roomId === 'room_group'))
                                .map((message, index) => (
                                <div key={index} className={`message ${message.senderId === user.id_user ? 'my-message' : ''}`}>
                                    <span className="message__text">{message.message}</span>
                                    <span className="message__time">{message.date}</span>
                                </div>
                            ))}
                        </div>

                        <div className="inputs">
                            <textarea placeholder="Wpisz wiadomość..."
                                      className="input-message"
                                      ref={TextAreaRef}
                                      value={message}
                                      onChange={handleOnChange}
                                      onKeyDown={handleKeyDown}
                            />
                            <Icon path={mdiSend} size={1}
                                  className="send-icon"
                                  onClick={handleSendMessage}
                            />
                        </div>
                    </React.Fragment>}
                </div>
            </div>
        </React.Fragment>
    )
}

export default ChatMessageOverlay;