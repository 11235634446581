import React, {useEffect, useState, useContext} from "react";
import {BrowserRouter as Router, useLocation} from "react-router-dom";
import AppRouter from "./AppRouter";

import {AuthContext} from './contexts/AuthContext';

import './assets/css/style.css';
import './assets/css/style_custom.css';
import '@mdi/font/css/materialdesignicons.css';

import { ReactComponent as SVGCNC } from './assets/images/cnc.svg';

import Navbar from './components/Navbar';
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import {UserContext} from "./contexts/UserContext";
import {fetchGetStatus} from "./api/ApiStatus";
import useAxios from "./api/useAxios";
import {fetchConfigureList} from "./api/ApiConfigureOther";
import Chat from "./components/chat/Chat";
import ReminderModal from "./components/projects/ReminderModal";

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const { authState, refreshToken } = useContext(AuthContext);
    const {user} = useContext(UserContext);
    const axiosInstance = useAxios();

    const [statuses, setStatuses] = useState([]);
    const [machines, setMachines] = useState([]);
    const [refreshSM, setRefreshSM] = useState(false);

    const [showReminder, setShowReminder] = useState(false);
    const [dataReminder, setDataReminder] = useState(null);

    const [theme, setTheme] = useState(localStorage.getItem("theme"));

    useEffect(() => {
        const timeoutRefreshToken = setTimeout(() => {
         //   console.log("TIMEOUT")
            refreshToken();
        }, 5000);

        const intervalRefreshToken = setInterval(() => {
            refreshToken();
        }, 3000000);

        return () => {
            clearInterval(intervalRefreshToken);
            clearTimeout(timeoutRefreshToken);
        }
    }, [])


    useEffect(() => {
        if (authState.isAuthenticated) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, [authState]);


    useEffect(() => {
        const existingLink = document.getElementById("theme-stylesheet");
        if (existingLink) {
            console.log(existingLink);
            existingLink.parentNode.removeChild(existingLink);
        }

        const link = document.createElement("link");
        link.id = "theme-stylesheet";
        link.rel = "stylesheet";
        link.href = theme === "light" ? "/assets/css/style_white.css" : "/assets/css/style2.css";
        document.head.appendChild(link);

        return () => {
            if (link.parentNode) {
                link.parentNode.removeChild(link);
            }
        };
    }, [theme]);

    const toggleTheme = () => {
        setTheme(theme === "light" ? "dark" : "light");
        localStorage.setItem("theme", theme === "light" ? "dark" : "light");
    };


    let navbarComponent = isAuthenticated && user ? <Navbar user={user} toggleTheme={toggleTheme}/> : '';
    let sidebarComponent = isAuthenticated && user ? <Sidebar user={user}/> : '';
    let footerComponent = isAuthenticated && user ? <Footer/> : '';

    useEffect(() => {
        if(isAuthenticated && user) {
            getStatuses();
            getMachines();
        }

        async function getStatuses() {
            const response = await fetchGetStatus(axiosInstance);

            if(response.status === 'success') {
                setStatuses(response.statuses);
            }
        }

        async function getMachines() {
            const response = await fetchConfigureList(axiosInstance, 3);

            if (response.status === 'success') {
                setMachines(response.data);
            }

            setRefreshSM(false);
        }
    }, [isAuthenticated, user, refreshSM])


    if(((!authState.isAuthenticated && !user && window.location.pathname !== '/login') || (authState.isAuthenticated && !user) || (authState.isAuthenticated && (statuses.length === 0 ||  machines.length === 0)))) {
        return (<div className="loading-animated-container">
            <SVGCNC className="loading-animated" />
        </div>)
    }

    return (
        <Router>
            <div className={`container-scroller ${theme === 'light' ? 'theme-light' : ''}`}>
                {sidebarComponent}
                <div className={`container-fluid page-body-wrapper ${!isAuthenticated ? 'full-page-wrapper' : ''}`}>
                    {navbarComponent}
                    <div className={`main-panel`} style={isAuthenticated ? {paddingTop: 70} : {paddingBottom: 0}}>
                        <div className="content-wrapper">
                            <AppRouter
                                statuses={statuses}
                                machines={machines}
                                setRefreshSM={setRefreshSM}

                                setShowReminder={setShowReminder}
                                setDataReminder={setDataReminder}
                            />

                            <Chat />
                            <ReminderModal show={showReminder} setShow={setShowReminder} idPart={dataReminder} />
                        </div>
                        {footerComponent}
                    </div>
                </div>
            </div>
        </Router>
    );
}

export default App;
