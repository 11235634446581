import React, {useEffect, useRef, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import Icon from "@mdi/react";
import {mdiPlus} from "@mdi/js";
import {typesStatus} from "../../../helpers/Status";
import {fetchDeleteStatus, fetchGetStatus, fetchUpdatePosition} from "../../../api/ApiStatus";
import useAxios from "../../../api/useAxios";
import {DroppableCustom} from "../../../components/DroppableCustom";
import {DragDropContext, Draggable} from "react-beautiful-dnd";

const Statuses = ({setRefreshSM}) => {
    const axiosInstance = useAxios();
    const location = useLocation();
    const messageLocation = location.state?.message;
    const statusLocation = location.state?.status;

    const [messages, setMessages] = useState({
        message: messageLocation ? messageLocation : '',
        status: statusLocation ? statusLocation : ''
    });

    const hasRunGetStatus = useRef(false);
    const [statuses, setStatuses] = useState([]);
    const [refreshStatus, setRefreshStatus] = useState(false);


    useEffect(() => {
        if (refreshStatus || (!statuses.length && !hasRunGetStatus.current)) {
            getStatuses();
        }

        async function getStatuses() {
            setRefreshStatus(true);
            const response = await fetchGetStatus(axiosInstance);

            if (response.status === 'success') {
                setStatuses(response.statuses);
            }

            setRefreshStatus(false);
            hasRunGetStatus.current = true;
        }
    }, [axiosInstance, refreshStatus, statuses]);

    const handleRemoveStatus = async (idStatus) => {
        const response = await fetchDeleteStatus(axiosInstance, idStatus);

        if (response.status === 'success') {
            setRefreshStatus(true);
            setMessages({
                message: response.message,
                status: 'success',
            });
        } else {
            setMessages({
                message: response.message,
                status: 'danger',
            });
        }
    }

    const onDragEnd = async (result) => {
        const { source, destination, draggableId } = result;

        if (!destination) {
            return;
        }

        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) {
            return;
        }

        const updatedStatuses = [...statuses];
        const draggedStatus = updatedStatuses.find(status => status.id.toString() === draggableId);

        updatedStatuses.splice(source.index, 1);

        updatedStatuses.splice(destination.index, 0, draggedStatus);

        setStatuses(updatedStatuses);

        let arr = [];
        updatedStatuses.forEach((status, index) => {
            arr.push({
                id: status.id,
                position_element: index + 1
            });
        });

        await fetchUpdatePosition(axiosInstance, arr);
        setRefreshSM(true);
    }

    return (
        <>
            <div className="page-header">
                <h3 className="page-title"> Konfiguracja statusów </h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">Konfiguracja statusów</li>
                    </ol>
                </nav>
            </div>
            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    {messages.message &&
                        <div className={`alert alert-${messages?.status} text-small`}>{messages.message}</div>}
                </div>
                <div className="col-md-12">
                    <div className="float-right">
                        <Link to={"/configure-status/create"} type="button"
                              className="btn btn-info btn-icon-text">
                            <Icon path={mdiPlus} size={0.7} className={"btn-icon-prepend"}/>
                            Dodaj status
                        </Link>
                    </div>
                </div>
            </div>

            <div className={"row"}>
                <DragDropContext onDragEnd={onDragEnd}>
                    {typesStatus.map((typeStatus, index) => (
                        <DroppableCustom key={`type_status_${index}`} droppableId={typeStatus.id.toString()}>
                            {(provided) => (
                                <div className="col-md-6 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <h3 className="page-title"> {typeStatus.name} </h3>
                                            <br/>
                                            <div className="table-responsive pb-5">
                                                <table className="table"
                                                       ref={provided.innerRef} {...provided.droppableProps}
                                                >
                                                    <thead>
                                                    <tr>
                                                        <th style={{width: '5%'}}>Lp.</th>
                                                        <th style={{width: '31.6%'}}>Wyświetlana nazwa</th>
                                                        <th style={{width: '31.6%'}}>Data utworzenia</th>
                                                        <th style={{width: '31.6%'}}>Akcje</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {refreshStatus && (
                                                        <tr>
                                                            <td colSpan="6" className="text-center">
                                                                <div className="py-4">
                                                                    <div className="spinner-border" role="status">
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {statuses.map((_status, i) => (
                                                        parseInt(_status.type_status) === parseInt(typeStatus.id) ? (
                                                            <Draggable key={_status.id.toString()}
                                                                       draggableId={_status.id.toString()}
                                                                       index={i}>
                                                                {(provided, snapshot) => (
                                                                    <tr ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}>
                                                                        <td>{_status.id}</td>
                                                                        <td>
                                                                            <label
                                                                                className={`badge badge-${_status.color}`}>{_status.display_name}</label>
                                                                        </td>
                                                                        <td>{_status.created_at}</td>
                                                                        <td>
                                                                            <div style={{display: 'flex', gap: '7px'}}>
                                                                                <Link
                                                                                    to={`/configure-status/edit/${_status.id}`}
                                                                                    type={'button'}
                                                                                    className={"btn btn-primary btn-fw"}>
                                                                                    Edytuj
                                                                                </Link>
                                                                                <button type="button"
                                                                                        className="btn btn-danger btn-fw"
                                                                                        onClick={() => handleRemoveStatus(_status.id)}>
                                                                                    Usuń
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </Draggable>
                                                        ) : null
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </DroppableCustom>
                    ))}
                </DragDropContext>
            </div>
        </>
    )
}

export default Statuses;