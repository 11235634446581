import React, {useContext, useEffect, useRef} from "react";
import {SocketContext} from "../../contexts/SocketContext";

const ChatUserList = ({isActive, setIsActive, openedChatMessages, setOpenedChatMessages, users}) => {
    const {socket} = useContext(SocketContext);

    const usersListRef = useRef(null);

    const handleClickOutside = (event) => {
        if (usersListRef.current && !usersListRef.current.contains(event.target)) {
            setIsActive(false);
        }
    };

    const handleOpenChat = (user) => {
        const userId = parseInt(user.id);// === -1 ? 0 : parseInt(user.id);
        const isUserOpened = openedChatMessages.find(message => message.id === userId);
        if(!isUserOpened) {
            user.id = userId;
            user.isClick = true;
            setOpenedChatMessages([...openedChatMessages, user]);
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if(!socket) return

        if(isActive) {
            socket.emit('user.list');
        }
    }, [isActive]);

    return (
        <React.Fragment>
            <div className={`chat-user-list ${isActive ? 'active' : ''}`} ref={usersListRef}>
                <div className="chat-title">
                    <h3>Czat</h3>
                </div>
                {users.map((user, index) => (
                    <div key={`chat-user_${index}`}
                         className="chat-user"
                         onClick={() => handleOpenChat(user)}
                    >
                        <div className="chat-user__avatar">
                            <img src={require('../../assets/images/default-avatar.jpg')} alt="profile"/>
                            <span className={`is-online ${user.isOnline ? 'bg-success' : 'bg-danger'}`}></span>
                        </div>
                        <div className="chat-user__info">
                            <div className="chat-user__name_box">
                                <span className="name">{user.display_name}</span>
                                <span className="time">
                                    {new Date(user.lastMessageDate).getUTCHours().toString().padStart(2, '0')}:
                                    {new Date(user.lastMessageDate).getUTCMinutes().toString().padStart(2, '0')}
                                </span>
                            </div>
                            <div className="chat-user__message_box">
                                <span className="message">{user.lastMessage}</span>
                                {user.noReadMessages > 0 && <span className="unread-message">{user.noReadMessages}</span>}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
}

export default ChatUserList;